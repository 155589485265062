import { Component } from '@angular/core';

@Component({
  selector: 'app-skltn-paginator',
  templateUrl: './skltn-paginator.component.html',
})
export class SkltnPaginatorComponent {

  constructor() { }

}
