import { Injectable } from '@angular/core';

import { RequestService } from '@vedrai/vedrai-core';
import { map } from 'rxjs/operators';
import { FullPeriodType, ICommodity, PeriodInstanceType, PeriodType } from '@models';
import { ForecastData } from '@vendors/modules/vedrai-kit';
import { today } from '../../utilities/dates';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ForecastsService {

    // TODO remove this map by changing with factory 
    fullPeriodTypes: { [K in PeriodType]: FullPeriodType } = {
        day: 'daily',
        week: 'weekly',
        month: 'monthly',
        quarter: 'quarterly',
        year: 'yearly'
    }

    constructor(private requestService: RequestService) { }

    getForecast(commodityId: number, period: PeriodType, date?: string) {
        return this.requestService.execRequest('get', `forecasts/commodities/${commodityId}`, {
            params: { today: date || today(), granularity: this.fullPeriodTypes[period] }
        }).pipe(map((forecastData) => new ForecastData<ICommodity>(forecastData, period)));
    }

    getForecastByPeriod(commodityId: number, period: PeriodInstanceType) {
        return this.requestService.execRequest('get', `forecasts/commodities/${commodityId}`, {
            params: {
                start_date: period.startDate,
                end_date: period.endDate,
                granularity: this.fullPeriodTypes[period.periodType]
            }
        }).pipe(map((forecastData) => new ForecastData<ICommodity>(forecastData, period.periodType)));
    }

}