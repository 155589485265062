export class IPagination {
    count: number;
    next: string;
    previous: string;
    results: any[];
}


export class Pagination<T> {
    count: number;
    next: string;
    previous: string;
    results: T[];

    constructor(response: IPagination, classType: new (item: any) => T) {
        Object.assign(this, response);

        this.results = response.results.map(item => new classType(item));
    }

}