import { ICompany } from './company.model';
import { IProduct } from './product.model';

export interface IUser {
    id: number;
    company: ICompany;
    modules: IProduct[];
    is_superuser: boolean;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    is_staff: boolean;
    is_active: boolean;
}

export class User {
    id: number;
    is_superuser: boolean;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    is_staff: boolean;
    is_active: boolean;

    constructor(user: IUser) {
        this.id = user.id;
        this.is_superuser = user.is_superuser;
        this.username = user.username;
        this.first_name = user.first_name;
        this.last_name = user.last_name;
        this.email = user.email;
        this.is_staff = user.is_staff;
        this.is_active = user.is_active;
    }
}