import { Directive, ElementRef, HostListener, Input, Renderer2 } from "@angular/core";

@Directive({
  selector: "[appHorizontalScroll]",
})
export class HorizontalScrollDirective {

  private _isEnabled: boolean = true;
  @Input() set enable(isEnabled: boolean) {
    this._isEnabled = isEnabled;
    this.nativeElement.scrollLeft = 0
    if (isEnabled) {
      this.renderer.addClass(this.nativeElement, 'overflow-x-auto');
    } else {
      this.renderer.removeClass(this.nativeElement, 'overflow-x-auto');
    }
  }

  get enable() {
    return this._isEnabled;
  }

  get nativeElement() {
    return this.element.nativeElement;
  }

  constructor(private element: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit() {
    this.enable = this._isEnabled;
  }

  @HostListener("wheel", ["$event"])
  public onScroll(event: WheelEvent) {
    if (this.isHorizontalScrollable(this.nativeElement)) {
      event.preventDefault();

      const { deltaX, deltaY } = event;
      const scrollDelta = Math.abs(deltaX) > Math.abs(deltaY) ? deltaX : deltaY;

      this.nativeElement.scrollLeft += scrollDelta;
    }
  }

  isHorizontalScrollable(el) {
    return el.scrollWidth > el.clientWidth && this.enable;
  }

}