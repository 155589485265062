import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'quarter'})
export class QuarterPipe implements PipeTransform {
  transform(quarterIndex: number): string {
    const quarter = (quarterIndex < 0 ? (4 + (quarterIndex % 4)) : (quarterIndex % 4)) || 4;
    const yearDiff = Math.ceil(quarterIndex / 4) - 1;
    const year = (new Date()).getFullYear() + yearDiff;

    return `${Math.abs(quarter)}° Trimestre ${year}`
  }
}