import { Injectable } from '@angular/core';
import { RequestService } from '@vedrai/vedrai-core';
import { map } from 'rxjs/operators';

import { Category, ICategory } from '@models';

@Injectable({
    providedIn: 'root'
})
export class CategoryService {

    constructor(private requestService: RequestService) { }

    getCommoditiesCategories(listed = true) {
        return this.requestService.execRequest('get', `commodities/categories`, {params: {listed}}).pipe(
            map((categories: ICategory[]) => categories.map((category) => new Category(category))));
    }

}