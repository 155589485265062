import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BreadCrumb } from '../../models/bread-crumb/bread-crumb.model';


@Component({
    selector: 'app-bread-crumb',
    templateUrl: './bread-crumb.component.html'
})
export class BreadCrumbComponent {

    @Input() inLoading: boolean;
    @Input() breadcrumbs: BreadCrumb[];
    @Input() moduleTitle: string;

    constructor(private router: Router) { }

    navigateTo(breadCrumbIndex: number) {
        this.router.navigate(this.flatCrumbs(this.breadcrumbs, breadCrumbIndex));
    }

    private flatCrumbs(breadCrumb: BreadCrumb[], index: number) {
        return breadCrumb.slice(0, index + 1).reduce((res, crumb) => res.concat(...crumb.route), [])
    }
}