import { roundDecimalsWithTresholds } from "@shared";

export interface IForecastBoundary {
    upper_bound: number;
    lower_bound: number;
}

export class ForecastBoundary implements IForecastBoundary {
    upper_bound: number;
    lower_bound: number;

    constructor(forecastBoundary: IForecastBoundary) {
        this.upper_bound = roundDecimalsWithTresholds(forecastBoundary.upper_bound);
        this.lower_bound = roundDecimalsWithTresholds(forecastBoundary.lower_bound);
    }
}