import { Directive, Input, forwardRef, HostListener, ElementRef, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, } from '@angular/forms';

@Directive({
    selector: 'input[type=checkbox][checkboxValue]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxDirective),
            multi: true
        }
    ]
})
export class CheckboxDirective implements ControlValueAccessor {
    private propagateChange = (_: any) => { };
    @Input() trueValue = true;
    @Input() falseValue = false;

    get nativeElement() {
        return this.elementRef.nativeElement;
    }

    constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

    @HostListener('change', ['$event'])
    onHostChange(ev) {
        const value = ev.target.checked ? this.trueValue : this.falseValue
        this.propagateChange(value);
        this.nativeElement.setAttribute('checked', value === this.trueValue);
    }

    writeValue(value: any): void {
        this.renderer.setProperty(this.nativeElement, 'checked', value === this.trueValue);
        this.nativeElement.setAttribute('checked', value === this.trueValue);
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void { }

    setDisabledState?(isDisabled: boolean): void { }
}