import { Component } from '@angular/core';
import { DataRouteService } from '@vedrai/vedrai-core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-main-layout',
    templateUrl: './main-layout.component.html'
})
export class MainLayoutComponent {

    dataRoute$: BehaviorSubject<any>;

    constructor(private dataRouteService: DataRouteService) {
        this.dataRoute$ = this.dataRouteService.dataRoute$;
    }

}
