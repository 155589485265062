import {
    faChartCandlestick,
    faChartColumn,
    faChartLine,
    faChartMixed,
    faCalendarDays,
    faHexagonMinus,
    faCircleXmark,
    faInfoCircle,
    IconDefinition
} from '@fortawesome/pro-regular-svg-icons';

export const far: IconDefinition[] = [
    faChartCandlestick,
    faChartColumn,
    faChartLine,
    faChartMixed,
    faHexagonMinus,
    faCircleXmark,
    faInfoCircle,
    faCalendarDays
];