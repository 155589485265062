import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxTranslateVendorModule } from '../ngx-translate/ngx-translate.vendor.module';
import { DatesAdapter, DatesParserFormatter } from './services/datepicker-adapter.service';
import { ModalsService } from './services/modals.service';
import { ComponentContainerModalComponent } from './widgets/component-container-modal/component-container-modal.component';
import { ConfirmModalComponent } from './widgets/confirm-modal/confirm-modal.component';

// TODO add only used components
@NgModule({
    imports: [
        NgbModule,
        CommonModule,
        NgxTranslateVendorModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        ComponentContainerModalComponent,
        ConfirmModalComponent
    ],
    exports: [ComponentContainerModalComponent, NgbModule],
})
export class NgBootstrapVendorModule {
    static forRoot(): ModuleWithProviders<NgBootstrapVendorModule> {
        return {
            ngModule: NgBootstrapVendorModule,
            providers: [
                ModalsService,
                { provide: NgbDateAdapter, useClass: DatesAdapter },
                { provide: NgbDateParserFormatter, useClass: DatesParserFormatter }
            ]
        }
    }
}