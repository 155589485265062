<aside>
  <nav class="navbar navbar-vertical navbar-expand-md navbar-light bg-white" id="sidenav-main">
    <div class="container-fluid">

      <button class="navbar-toggler" type="button" (click)="isCollapsed=!isCollapsed"
        aria-controls="sidenav-collapse-main">
        <span class="navbar-toggler-icon"></span>
      </button>

      <a class="navbar-brand app-avatar pt-0" [routerLink]="['/dashboard']">
        <picture>
          <source class="navbar-brand-img" srcset="assets/images/virtual-agent/Avatar_Becky.svg" media="(min-width: 768px)">
          <img class="navbar-brand-img" srcset="assets/images/virtual-agent/Becky_name.svg" alt="Becky Logo">
        </picture>
      </a>

      <app-user-menu class="d-md-none d-flex" [mobile]="true"></app-user-menu>

      <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed" id="sidenav-collapse-main">
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <a routerLinkActive="active" [routerLink]="['/dashboard']">
                <img src="assets/images/virtual-agent/Becky_name.svg">
              </a>
            </div>
            <div class="col-6 collapse-close">
              <button type="button" class="navbar-toggler">
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>


        <hr class="mb-4 mt-0 d-none d-md-block">

        <!-- <app-user-menu class="d-none d-md-flex" [mobile]="false"></app-user-menu>


        <hr class="my-3 d-none d-md-block"> -->

        <app-menu (onSelect)="isCollapsed = true"></app-menu>

      </div>
    </div>
  </nav>
</aside>