
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import notificationConfigs from '@configs/notifications/notifications.json';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { PNotification, NNotification, Notification } from '../models/notification.model';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    notifications$: BehaviorSubject<Notification[]>;
    progressions$: { scenario: BehaviorSubject<{ [key: string]: PNotification }> };
    notificationsNumber$: BehaviorSubject<number>;
    messages: any;

    get notificationNumber() {
        return this.notificationsNumber$.getValue();
    }

    get notifications() {
        return this.notifications$.getValue()
    }

    set notifications(notifications: Notification[]) {
        this.notifications$.next(notifications);
        this.notificationsNumber$.next(notifications.length);
    }

    constructor(
        private translateService: TranslateService,
        private toastr: ToastrService) {
        this.messages = notificationConfigs.messages;
        this.notifications$ = new BehaviorSubject([]);
        this.progressions$ = {
            scenario: new BehaviorSubject({})
        }
        this.notificationsNumber$ = new BehaviorSubject(0);
    }

    manageNotifications(message: string) {
        const notification: Notification = new Notification(message);

        switch (notification.type) {
            case 'N':
                this.addNotification(notification);
                break;
            case 'P':
                this.addProgression(notification);
                break;
        }
    }

    addNotification(notification: Notification) {
        const { status, display_name } = <NNotification>notification.value;
        const { title, description, notify } = this.getNotify(status, display_name);
        notification.title = title;
        notification.description = description;
        notification.notify = notify;

        this.notifications = [notification, ...this.notifications];
        this.toastr[notify](description, title);
    }

    addProgression(notification: Notification) {
        this.setProgressEntity(<PNotification>notification.value);
    }

    getNotify(status: string = '500', displayName: string) {

        const { title, description, notify } = this.messages[status] || {};
        return {
            title: this.translateService.instant(title, { name: displayName }),
            description: description ? this.translateService.instant(description, { name: displayName }) : '',
            notify
        }
    }

    clearNotification() {
        this.notifications = [];
    }

    // PROGRESSION NOTIFICATIONS

    getProgressEntity(entityType: string) {
        return this.progressions$[entityType] || of({});
    }

    setProgressEntity(progression: PNotification) {
        const { id, entityType } = progression;

        return this.progressions$[entityType].next({
            ...this.getProgressEntity(entityType).getValue(),
            [id]: progression
        })
    }


}