import { Component } from '@angular/core';
import { NotificationsService } from '@vendors/modules/ng2-stompjs';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-notifications-menu',
  templateUrl: './notifications-menu.component.html'
})
export class NotificationsMenuComponent {

  notifications$: BehaviorSubject<any>;
  notificationsNumber$: BehaviorSubject<number>;

  constructor(private notificationsService: NotificationsService) {
    this.notifications$ = this.notificationsService.notifications$;
    this.notificationsNumber$ = this.notificationsService.notificationsNumber$;
  }

  clearNotifications() {
    this.notificationsService.clearNotification();
  }

}
