import { ActivatedRoute } from '@angular/router';
import { BreadCrumb } from './bread-crumb.model';

export class BreadCrumbGroup {

    safeDataKeys: string[] = ['title', 'group', 'titleKey'];
    title: string;
    group: { [key in string]: {
        index: number,
        breadCrumb: BreadCrumb
    } } = {};

    private previousData;

    constructor() {
        this.group = {};
        this.previousData = {};
    }

    addBreadCrumb(route: ActivatedRoute) {

        const { moduleTitle, group, title } = this.getCurrentData(route);

        this.title = moduleTitle || this.title;

        if (!group && !title) return;

        const groupName = group || this.getIncrementialName();

        if (this.group[groupName]) {
            this.updateBreadCrumb(groupName, route);
        } else {
            this.createBreadCrumb(groupName, route);
        }
    }

    getBreadCrumbs() {
        return Object.keys(this.group)
            .map((key) => this.group[key])
            .sort((a, b) => a.index > b.index ? 1 : -1)
            .map(({ breadCrumb }) => breadCrumb);
    }

    private updateBreadCrumb(groupName: string, route: ActivatedRoute) {
        this.group[groupName].breadCrumb.updateBreadCrumb(route);
    }

    private createBreadCrumb(groupName: string, route: ActivatedRoute) {
        this.group[groupName] = {
            breadCrumb: new BreadCrumb(route),
            index: this.getGroupsLength()
        };
    }

    private getGroupsLength() {
        return Object.keys(this.group).length;
    }

    private getIncrementialName() {
        return `uniq_group_${this.getGroupsLength()}`;
    }

    private getCurrentData(route: ActivatedRoute) {
        let pathData = route.snapshot.data;
        for (let key in this.previousData) {
            if (!this.safeDataKeys.includes(key)) delete pathData[key];
        }
        this.previousData = { ...this.previousData, ...pathData };
        return pathData;
    }
}