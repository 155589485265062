import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptions } from '@vendors/modules/ng-bootstrap';
import { ComponentContainerModalComponent } from '@vendors/modules/ng-bootstrap';
import { ConfirmModalComponent } from '../widgets/confirm-modal/confirm-modal.component';

@Injectable()
export class ModalsService {

    constructor(private modalService: NgbModal) { }

    openModal(modalOptions: ModalOptions<any>): NgbModalRef {

        if (!modalOptions) throw ("Modal options not found");

        const { component, isEmbedded, data, size, classes } = modalOptions;
        let modalData = data, modalComponent = component;

        if (isEmbedded) {
            modalData = { ...data, component };
            modalComponent = ComponentContainerModalComponent;
        }

        const modalRef = this.modalService.open(
            modalComponent,
            { size, ...classes, centered: true }
        );
        modalRef.componentInstance.data = modalData;
        
        return modalRef;
    }

    openConfirmModal(modalOptions: any): NgbModalRef {

        const { data, size, classes } = modalOptions;

        const modalRef = this.modalService.open(
            ConfirmModalComponent,
            { size, ...classes, centered: true }
        );

        modalRef.componentInstance.data = data;

        return modalRef;
    }

    dismissAllModals() {
        return this.modalService.dismissAll();
    }
}