import { PeriodsFactory } from "./periods.factory";

export type ExtractInstanceType<T> = T extends new () => infer R ? R : any;

export type PeriodType = keyof typeof PeriodsFactory.periods;

export type PeriodClassType = typeof PeriodsFactory.periods[PeriodType];

export type PeriodInstanceType = ExtractInstanceType<PeriodClassType>;

const firstLetters = [], plurals = [], recursivly = [];
for (let key in PeriodsFactory.periods) {
    firstLetters.push(key[0].toUpperCase());
    plurals.push(key + 's');
    recursivly.push(key == 'day' ? 'daily' : key + 'ly');
}

const shortPeriodTypeLetters = [...firstLetters] as const;
const periodTypePlurals = [...plurals] as const;
const periodTypeRecursivly = [...recursivly] as const;

export type ShortPeriodType = typeof shortPeriodTypeLetters[number];

export type PluralPeriodType = typeof periodTypePlurals[number];

export type RecursivlyPeriodType = typeof periodTypeRecursivly[number];

// TODO: dismiss
export type FullPeriodType = typeof periodTypeRecursivly[number];


export type PeriodTypes = PeriodType | ShortPeriodType | PluralPeriodType | RecursivlyPeriodType;