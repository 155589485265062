<div class="input-group">
    <ng-content select="[prepend]"></ng-content>
    <input #selectInput type="text" class="form-control" [id]="name" [name]="name" [tabindex]="index" [ngModel]="label"
        [placeholder]="placeholder | translate" [title]="placeholder | translate" (focus)="onFocus()" readonly
        (blur)="onBlur($event)" (ngModelChange)="onModelChange($event)" [ngClass]="{
        'is-invalid': !!selectedOption[options?.colorKeys?.error],
        'is-valid warning': !!selectedOption[options?.colorKeys?.warning]
    }" [ngStyle]="{'border-color': selectedOption | optionsListStyle : options?.styleFn }"
        [class]="selectedOption | optionsListStyle : options?.classFn" [disabled]="disabled" autocomplete="off"
        [attr.aria-invalid]="ariaInvalid">
</div>

<div *ngIf="openSelect && (optionsList.length || inLoading)" class="select-container" aria-live="polite">

    <div class="select px-2" #dropdown>
        <div class="options-container" [class.active]="optionsList.length">
            <ng-container *ngFor="let option of optionsList; let i = index">
                <div [tabindex]="index" [id]="'opt-' + i" class="options" (blur)="onBlur($event)"
                    [ngStyle]="{'color': option | optionsListStyle : options?.styleFn }"
                    [class]="option | optionsListStyle : options?.classFn" [ngClass]="{
                            'error': !!option[options?.colorKeys?.error],
                            'warning': !!option[options?.colorKeys?.warning]
                        }" (mousedown)="selectOption(option)" (keydown.enter)="selectOption(option)">
                    <span>{{option | optionsListLabel : options?.labelKey : options?.outputKey}}</span>
                </div>
            </ng-container>
            <div *ngIf="inLoading" class="loader-container">
                <vedrai-local-loader [inLoading]="true"></vedrai-local-loader>
            </div>
        </div>
    </div>
</div>
<div class="icons-container">
    <div class="select-icons" [class.open]="openSelect && optionsList.length" [class.disabled]="disabled">
        <span *ngIf="label && !disabled && showRemoveBtn" class="remove-icon" [attr.tabindex]="index"
            (keydown.enter)="onModelChange('')" (click)="onModelChange('')">
            &times;
        </span>
    </div>
</div>