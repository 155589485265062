import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { map, tap, mergeMap } from 'rxjs/operators';

import { RequestService } from '@vedrai/vedrai-core';
import { environment } from '@env/environment';
import { IUser } from '../../models/user.model';
import { UserService } from '../user/user.service';
import { ConfigsService } from './configs.service';
// import { NotificationsConfigService } from '@vendors/modules/ng2-stompjs';

@Injectable()
export class AuthService {

    isLoggedIn$: BehaviorSubject<boolean>;

    constructor(
        private requestService: RequestService,
        // private authNotificationsService: AuthNotificationsService,
        private configsService: ConfigsService,
        private router: Router,
        private userService: UserService) {
        this.isLoggedIn$ = new BehaviorSubject(false);
    }

    get isLoggedIn() {
        return this.isLoggedIn$.getValue();
    }

    set isLoggedIn(value: boolean) {
        this.isLoggedIn$.next(value);
    }

    get isVirtualAgentActive() {
        return this.userService.isProductActive;
    }

    async initAuthentication(): Promise<any> {

        try {
            const user = await this.retrieveUser();

            await this.initAuth(user.id);

        } catch (error) {
            this.isLoggedIn = false;
            this.accessRedirect();
            console.warn('Not authorized: ', error);
        }

    }

    signIn(username: string, password: string) {
        return this.requestService.execSSORequest('post', 'login', { username, password })
            .pipe(
                mergeMap(() => this.retrieveUser()),
                tap(async (user) => {
                    await this.initAuth(user.id);
                    this.dashboardRedirect();
                })
            );
    }

    logout() {
        this.requestService.execSSORequest('post', 'logout')
            .subscribe(() => this.applyLogout());

        // this.authNotificationsService.logoutNotificationsBroker(this.userService.userId)
        // .subscribe();
        // .pipe(mergeMap(() => this.requestService.execSSORequest('post', 'logout')))
        // .subscribe(() => this.applyLogout());

    }

    applyLogout() {
        this.isLoggedIn = false;
        this.accessRedirect();
    }

    private async initAuth(userId) {

        if (!this.isVirtualAgentActive) {
            this.notAcquiredRedirect();
            throw 'Virtual agent is not activated';
        }

        await this.configsService.initConfigs();
        this.isLoggedIn = true;

        // this.authNotificationsService.initAuthentication(userId);
    }

    private async retrieveUser() {
        return this.userService.getUser()
            .pipe(
                map(user => {
                    // TODO implement more sofisticated check
                    if (user?.id) return user;

                    throw new Error('User is invalid');
                }),
                tap((user: IUser) => this.userService.consumeUser(user))
            ).toPromise();
    }

    private dashboardRedirect() {
        this.router.navigate(['/dashboard']);
    }

    private accessRedirect() {
        this.router.navigate(['/access']);
    }

    private notAcquiredRedirect() {
        this.router.navigate(['/access', 'not-acquired']);
    }

    marketplaceRedirect() {
        window.location.replace(environment.urlMarketplace);
    }

}