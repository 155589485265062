import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IModalConfirm } from '../../models/modals/modal-confirm.model';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent {
  
  data: IModalConfirm;

  get description() {
    return this.data.description;
  }

  get confirmLabel() {
    return this.data.confirmLabel;
  }

  get closeLabel() {
    return this.data.closeLabel;
  }

  constructor(private activeModalService: NgbActiveModal) { }

  onConfirm() {
    this.data.confirm();
    this.activeModalService.close();
  }

  onClose() {
    this.activeModalService.close();
  }
}
