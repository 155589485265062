import { PeriodInstanceType, PeriodsFactory, PeriodType } from '@models';
import { roundDecimalsWithTresholds } from '@shared';
import { Forecast, IForecast } from "./forecast.model";

export interface IForecastGroup {
    target_date: string;
    start_date: string;
    end_date: string;
    selected: boolean;
    value: number;
    forecasts: IForecast[];
}


export class ForecastGroup {

    target_date: string;
    start_date: string;
    end_date: string;
    selected: boolean;
    value: number;

    periodType: PeriodType;
    targetPeriod: PeriodInstanceType;

    forecasts: Forecast[] = [];

    lastForecast: Forecast;
    roundedValue: number;

    constructor(forecastGroup: IForecastGroup, periodType: PeriodType) {
        this.periodType = periodType;

        this.target_date = forecastGroup.target_date;
        this.start_date = forecastGroup.start_date;
        this.end_date = forecastGroup.end_date;
        this.selected = forecastGroup.selected;
        this.value = forecastGroup.value;
        this.roundedValue = roundDecimalsWithTresholds(this.value);

        this.targetPeriod = PeriodsFactory.createPeriod(periodType, null, this.target_date);
        this.forecasts = this.getForecasts(forecastGroup.forecasts);
        this.lastForecast = this.forecasts[0];
    }

    get trend() {
        return this.isForFuture ? this.lastForecast?.forecast_trend : this.lastForecast?.real_trend;
    }

    get isStable() {
        return this.lastForecast?.isStable;
    }

    get isForFuture() {
        return this.value == null;
    }

    private getForecasts(forecasts: IForecast[]) {
        return forecasts.map((forecast: IForecast) => new Forecast(forecast, this.periodType));
    }

}