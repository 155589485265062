import { ModuleWithProviders, NgModule } from "@angular/core";
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { NgxGoogleAnalyticsService } from './services/ngx-google-analitycs.service';

@NgModule({
    imports: [
        NgxGoogleAnalyticsModule,
        NgxGoogleAnalyticsRouterModule,
    ],
    exports: [
        NgxGoogleAnalyticsModule,
        NgxGoogleAnalyticsRouterModule
    ]
})
export class NgxGoogleAnalyticsVendorModule {
    static forRoot(): ModuleWithProviders<NgxGoogleAnalyticsVendorModule> {
        return {
            ngModule: NgxGoogleAnalyticsVendorModule,
            providers: [NgxGoogleAnalyticsService]
        }
    }
}