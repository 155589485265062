import moment from "moment";
import { Moment } from "moment";

export interface IPeriodDates {
    startDate: string;
    endDate: string;
}

export interface IPeriod extends IPeriodDates {

    getFullFormat: Function;
    getShortFormat: Function;
}

export class Period {

    start: PeriodDate;
    end: PeriodDate;

    get startDate() {
        return this.start.date;
    }

    set startDate(startDate: string) {
        this.start = new PeriodDate(startDate);
    }

    get endDate() {
        return this.end.date;
    }

    set endDate(endDate: string) {
        this.end = new PeriodDate(endDate);
    }

    constructor(startDate?: string, endDate?: string) {
        this.startDate = startDate;
        this.endDate = endDate;
    }

    fetchFullDate(format: Function) {
        this.start.fetchFullDate(format);
        this.end.fetchFullDate(format);
    }

    fetchShortDate(format: Function) {
        this.start.fetchShortDate(format);
        this.end.fetchShortDate(format);
    }

}

export class PeriodDate {

    date: string;
    private _momentDate: Moment;

    fullDate: string;
    shortDate: String;

    get momentDate() {
        return this._momentDate.clone();
    }

    set momentDate(date: Moment) {
        this._momentDate = date;
    }

    constructor(date: string) {
        this.date = date;
        this.momentDate = moment(date);
    }

    fetchFullDate(format: Function) {
        this.fullDate = format(this.momentDate);
    }

    fetchShortDate(format: Function) {
        this.shortDate = format(this.momentDate);
    }

}