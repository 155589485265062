import {
    faChartUser,
    faMagnifyingGlass,
    faMoneyCheckDollarPen,
    faChartLineUp,
    IconDefinition,
    faCalendarDays
} from '@fortawesome/pro-duotone-svg-icons';

export const fad: IconDefinition[] = [
    faChartUser,
    faMagnifyingGlass,
    faMoneyCheckDollarPen,
    faChartLineUp,
    faCalendarDays
];