import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { registerLocaleData } from '@angular/common';
import { environment } from '@env/environment';
import { LanguageType } from '../models/types.model';


// TODO to kit
@Injectable({
    providedIn: 'root'
})
export class BaseLanguageService {

    language$: BehaviorSubject<string>;
    languages$: BehaviorSubject<LanguageType[]>;

    get language() {
        return this.language$.getValue();
    }

    set language(lang: string) {
        this.language$.next(lang);
    }

    get languages(): LanguageType[] {
        return this.languages$.getValue();
    }

    private set languages(languages: LanguageType[]) {
        this.languages$.next([...languages]);
    }

    constructor(private translate: TranslateService) {
        this.language$ = new BehaviorSubject(null);
        this.languages$ = new BehaviorSubject(environment.languages);
    }

    localeInitializer(localeId: LanguageType): Promise<any> {
        return import(
            /* webpackInclude: /(it|en|es)\.mjs/ */
            /* webpackMode: "lazy-once" */
            /* webpackChunkName: "i18n-base" */
            `/node_modules/@angular/common/locales/${localeId}.mjs`
        ).then(module => registerLocaleData(module.default));
    }

    setMomentLanguage(language: LanguageType) {
        moment.locale(language);
    }

    async useLanguage(language: LanguageType) {
        return this.translate.use(language).toPromise();
    }

    storeLanguage(language: LanguageType) {
        localStorage.setItem('lang', language);
    }

    isLanguageAvailable(language: LanguageType) {
        return this.languages.indexOf(language) != -1;
    }

    getSupportedLanguage() {
        return this.languages[0];
    }

}