import { trigger, state, style, transition, animate } from "@angular/animations";

export const flipAnimation = trigger('flipState', [
    state('active', style({
        transform: 'rotateY(179deg)'
    })),
    state('inactive', style({
        transform: 'rotateY(0)'
    })),
    transition('active => inactive', animate('400ms ease-out')),
    transition('inactive => active', animate('400ms ease-in'))
])