// TODO add import for only used charts https://echarts.apache.org/en/tutorial.html#Use%20ECharts%20with%20bundler%20and%20NPM
//  GridSimpleComponent => '../component/grid/installSimple.js';
//  GridComponent => '../component/grid/install.js';
//  PolarComponent => '../component/polar/install.js';
//  RadarComponent => '../component/radar/install.js';
//  GeoComponent => '../component/geo/install.js';
//  SingleAxisComponent => '../component/singleAxis/install.js';
//  ParallelComponent => '../component/parallel/install.js';
//  CalendarComponent => '../component/calendar/install.js';
//  GraphicComponent => '../component/graphic/install.js';
//  ToolboxComponent => '../component/toolbox/install.js';
//  TooltipComponent => '../component/tooltip/install.js';
//  AxisPointerComponent => '../component/axisPointer/install.js';
//  BrushComponent => '../component/brush/install.js';
//  TitleComponent => '../component/title/install.js';
//  TimelineComponent => '../component/timeline/install.js';
//  MarkPointComponent => '../component/marker/installMarkPoint.js';
//  MarkLineComponent => '../component/marker/installMarkLine.js';
//  MarkAreaComponent => '../component/marker/installMarkArea.js';
//  LegendComponent => '../component/legend/install.js';
//  LegendScrollComponent => '../component/legend/installLegendScroll.js';
//  LegendPlainComponent => '../component/legend/installLegendPlain.js';
//  DataZoomComponent => '../component/dataZoom/install.js';
//  DataZoomInsideComponent => '../component/dataZoom/installDataZoomInside.js';
//  DataZoomSliderComponent => '../component/dataZoom/installDataZoomSlider.js';
//  VisualMapComponent => '../component/visualMap/install.js';
//  VisualMapContinuousComponent => '../component/visualMap/installVisualMapContinuous.js';
//  VisualMapPiecewiseComponent => '../component/visualMap/installVisualMapPiecewise.js';
//  AriaComponent => '../component/aria/install.js';
//  TransformComponent => '../component/transform/install.js';
//  DatasetComponent => '../component/dataset/install.js';


// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echartsCore from 'echarts/core';

// Import bar charts, all with Chart suffix
import { BarChart, CandlestickChart, LineChart, GaugeChart } from 'echarts/charts';

import { TooltipComponent, GridComponent, VisualMapComponent, GeoComponent, LegendComponent, DataZoomComponent, ToolboxComponent } from 'echarts/components';
// Import the Canvas renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step

import { CanvasRenderer } from 'echarts/renderers';
import { chartsThemes } from '../charts/charts-themes.model';

echartsCore.use([
    TooltipComponent,
    GridComponent,
    VisualMapComponent,
    GeoComponent,
    LegendComponent, 
    DataZoomComponent, 
    ToolboxComponent,
    BarChart,
    CandlestickChart,
    LineChart,
    CanvasRenderer,
    GaugeChart
]);

chartsThemes.forEach(({ theme }) => echartsCore.registerTheme(
    theme.themeName,
    theme.theme
));

export const echarts = echartsCore;