import { PeriodInstanceType, PeriodType, PeriodsFactory } from "@models";
import { roundDecimal } from "@shared";
import { ForecastGuessedType, ForecastTrendType, OriginalForecastTrendType } from "./forecast-types.model";

const FORECAST_TRENDS: {
    [key in OriginalForecastTrendType]: ForecastTrendType
} = {
    UP: 'ascending',
    STAT: 'stable',
    STDO: 'stable-down',
    STUP: 'stable-up',
    DOWN: 'descending'
}
export interface IForecast {
    confidence: number;
    timedelta: number;
    forecast_trend: string;
    real_trend: string;
    guessed: ForecastGuessedType;
    delta: number;
    reference: {
        boundary: number;
        target_date: string;
        value: number;
    };
}

export class Forecast {
    confidence: number;
    timedelta: number;
    forecast_trend: ForecastTrendType;
    real_trend: ForecastTrendType;
    guessed: ForecastGuessedType;
    delta: number;
    boundary: number;
    lowerInterval: number;
    upperInterval: number;

    target_date: string;
    value: number;
    
    targetPeriod: PeriodInstanceType;

    get isStable() {
        return this.trend == 'stable' || this.trend == 'stable-up' || this.trend == 'stable-down';
    }

    get isForFuture() {
        return this.guessed === null;
    }

    get trend() {
        return this.isForFuture ? this.forecast_trend : this.real_trend;
    }

    constructor(forecast: IForecast, periodType: PeriodType) {

        this.confidence = roundDecimal(forecast.confidence * 100);
        this.timedelta = forecast.timedelta;
        this.forecast_trend = FORECAST_TRENDS[forecast.forecast_trend];
        this.real_trend = FORECAST_TRENDS[forecast.real_trend];
        this.guessed = forecast.guessed;
        this.delta = forecast.delta;
        
        this.boundary = forecast.reference?.boundary;
        this.target_date = forecast.reference?.target_date;
        this.value = forecast.reference?.value;
        
        this.lowerInterval = this.value - this.boundary;
        this.upperInterval = this.value + this.boundary;

        this.targetPeriod = PeriodsFactory.createPeriod(periodType, null, this.target_date);
    }

}