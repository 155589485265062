import { PipeTransform, Pipe } from '@angular/core';
import { get } from '@vedrai/vedrai-core';

@Pipe({
    name: 'optionsListLabel'
})
export class OptionsListLabelPipe implements PipeTransform {

    transform(option: any, labelKey: string, outputKey: string) {
        if (typeof option !== 'string') {
            return labelKey ? get(option, labelKey) : this.getOptionValue(option, outputKey);
        } else {
            return option;
        }
    }

    /**
    * get value from options list by outputKey
    * @param option option from options list
    */
    private getOptionValue(option: any, outputKey: string) {
        return outputKey ? get(option, outputKey) : option;
    }
}