export function getStoredLanguage() {
    const storedLanguage = getLocalStorageLangauge()
    if (!!storedLanguage) return storedLanguage;

    return getShortLanguage(getBrowserLanguage()) || 'en';
}

function getLocalStorageLangauge() {
    return localStorage.getItem('lang');
}

function getShortLanguage(language: string) {
    return language.split('-')[0];
}

function getBrowserLanguage() {
    return navigator.language;
}