import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { StatusType } from '@core';

import statusesConfigs from '@configs/statuses.json';

@Injectable()
export class UtilsService {

    inLoading$: BehaviorSubject<boolean>;
    toasts: any = {};

    constructor(

        private toastr: ToastrService,
        private translateService: TranslateService) {
        this.inLoading$ = new BehaviorSubject(false);

        this.toasts = {
            error: {
                title: 'operations.operation-error',
                description: 'notifications.error'
            },
            success: {
                title: 'operations.operation-success',
                description: 'notifications.success'
            },
            warning: {
                title: 'operations.operation-warning',
                description: 'notifications.warning'
            },
            info: {
                title: 'operations.operation-info'
            }
        }

    }

    inLoading(value: boolean) {
        this.inLoading$.next(value);
    }

    scrollTo(elementName: string) {
        const element = document.querySelector(elementName);
        element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }

    openToast(type: StatusType, option: any, translationValues?: any) {
        let { title, description } = this.toasts[type] || {};
        title = this.translateService.instant(option?.title || title, translationValues),
            description = description ? this.translateService.instant(option?.description || description, translationValues) : ''

        this.toastr[type](description, title);
    }

    getStatuses(statusGroup: string) {
        return statusesConfigs[statusGroup];
    }

}