import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class UrlParamsService {

    constructor(private route: ActivatedRoute, private router: Router) { }

    updateParams(queryParams: any) {
        this.router.navigate([], {
            replaceUrl: true,
            queryParams
        });
    }

    getParams() {
      return this.route.snapshot.queryParams;
    }

    getParam(paramName: string) {
        return this.getParams()[paramName];
    }
}