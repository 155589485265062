import {
    Component, Input, forwardRef, ChangeDetectorRef, ChangeDetectionStrategy,
    OnInit, ViewChild, Output, EventEmitter, HostListener, OnChanges, SimpleChanges
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, ControlValueAccessor, FormControl } from '@angular/forms';
import { take, finalize } from 'rxjs/operators';
import { get } from '@vedrai/vedrai-core';
import { IOptionSelection, ISelectOptions, SelectOptions, valueType } from '@vedrai/vedrai-ui';

const SELECT_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectComponent),
    multi: true
};

const VALIDATOR_ACCESSOR = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => SelectComponent),
    multi: true,
}

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SELECT_ACCESSOR, VALIDATOR_ACCESSOR],
    host: {
        class: 'vedrai-select'
    }
})
export class SelectComponent implements OnInit, ControlValueAccessor, Validator, OnChanges {


    @Input() placeholder: string;
    @Input() options: ISelectOptions;

    // initialize label field
    @Input() selectList: Array<any>;
    @Input() retrieveCall: Function;
    @Input() disabled: boolean;
    @Input() showRemoveBtn: boolean = true;
    @Input() name: string;
    @Input() index: number = 1;
    @Output() onSelection: EventEmitter<IOptionSelection> = new EventEmitter();
    @Input() ariaInvalid: boolean = false;

    optionsList: Array<any>;
    openSelect: boolean = false;
    selectedOption: any = {};
    isOptionSelected: boolean = false;
    label: string;
    inLoading: boolean = false;

    @ViewChild('selectInput', { static: true }) selectInput: any;
    @ViewChild('dropdown', { static: false }) dropdown: any;

    private _value: valueType;
    private _outputKey: string;
    private _labelKey: string;
    private _styleOnlyAuto: boolean;
    private _optionsFocusIndex: number = -1;

    constructor(private cdr: ChangeDetectorRef) {
        this.optionsList = [];
    }

    ngOnInit() {
        if (this.options) {
            this.options = new SelectOptions(this.options);
            this._outputKey = this.options.outputKey;
            this._labelKey = this.options.labelKey;
            this._styleOnlyAuto = this.options.styleOnlyAuto;
        }

        if (typeof this.retrieveCall == 'function') {
            this.inLoading = true;
            this.retrieveCall().pipe(
                take(1),
                finalize(() => this.inLoading = false)
            ).subscribe((options: Array<any>) => {
                this.cdr.markForCheck();
                this.optionsList = options;
            });
        } else {
            if (this.selectList && Array.isArray(this.selectList)) {
                this.optionsList = this.selectList;
            } else {
                this.selectList = null;
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.selectList && !changes.selectList?.firstChange) {
            if (Array.isArray(this.selectList)) {
                this.optionsList = this.selectList;
                if (!this.selectList.length) {
                    this.label = '';
                    setTimeout(() => {
                        this.setValue(null);
                    }, 0);
                }
            } else {
                this.selectList = null;
            }
        }
    }

    //SETTERS / GETTERS

    get value(): any {
        return this._value;
    };

    set value(v: any) {
        if (v !== this._value) {
            this._value = v;

            this.cdr.markForCheck();
        }
    }

    @HostListener('keypress', ['$event']) onKeyPress(e: any) {
        e.preventDefault();
    }

    @HostListener('keydown', ['$event']) onKeyDown(e: any) {

        if (!this.openSelect || (this.openSelect && !this.optionsList.length)) return;
        if (e.key == 'Tab') {
            this.openSelect = false;
            return;
        }
        if (!/ArrowUp|ArrowDown/.test(e.key)) return;

        e.preventDefault();

        const keyName = e.key;

        if (this._optionsFocusIndex == -1) {
            this._optionsFocusIndex = 0;
        } else if (keyName == 'ArrowUp') {
            this._optionsFocusIndex--;
        } else if (keyName == 'ArrowDown') {
            if (this._optionsFocusIndex != this.optionsList.length - 1)
                this._optionsFocusIndex++;
        }

        if (this._optionsFocusIndex == -1) {
            this.selectInput.nativeElement.focus();
        } else {
            const currentElement = document.getElementById(`opt-${this._optionsFocusIndex}`);
            currentElement.focus();
            if (this._optionsFocusIndex) {
                this.dropdown.nativeElement.scrollTop = currentElement.offsetTop - 10;
            } else {
                this.dropdown.nativeElement.scrollTop = 0;
            }
        }
    }

    /**
     * Set value is used to setup output ngModel value
     * @param value string or number to change
     */
    setValue(value: valueType) {
        this.value = value;
        this.onChange(value)
    }

    /**
     * get value from select option by options outputKey
     * @param option option from select
     */
    getOptionValue(option: any) {
        return this._outputKey ? get(option, this._outputKey) : option;
    }

    /**
     * get label from select option by options labelKey
     * @param option option from select
     */
    getOptionLabel(option: any) {
        if (typeof option !== 'string') {
            return this._labelKey ? get(option, this._labelKey) : this.getOptionValue(option);
        } else {
            return option;
        }
    }

    /**
     * On model change is used to reset output value or to reset value from options list 
     * @param value  string or number inputed from user to change
     */
    onModelChange(value: string) {
        const option = this.optionsList.find((option) => this.getOptionLabel(option) === value);
        this.label = value;
        this.setValue(!!option ? this.getOptionValue(option) : null);

        this.selectedOption = this._styleOnlyAuto ? {} : option || {};

        this.selectInput.invalid = !option;

        this.emitSelection(option);

        this.cdr.markForCheck();
    }

    /**
     * Select option from select list
     * @param option abject or string to select
     */
    selectOption(option: any) {
        this.label = this.getOptionLabel(option);
        this.setValue(this.getOptionValue(option));
        this.selectedOption = this._styleOnlyAuto ? {} : option;
        this.openSelect = false;

        this.emitSelection(option);

        this.cdr.markForCheck();
    }

    /**
     * Emit selection in select envent
     * @param option current option selected
     */
    emitSelection(option: any) {
        if (this.isOptionSelected && !option) {
            this.isOptionSelected = false;
            this.onSelection.emit({
                label: null,
                value: null,
                option: {}
            });
        }
        if (option) {
            this.isOptionSelected = true;
            this.onSelection.emit({
                label: this.label,
                value: this.value,
                option: option
            });
        }
    }

    /**
     * On input focus open select or do the first call to retrieve data and than open select
     */
    onFocus() {
        this.openSelect = true;
        this._optionsFocusIndex = -1;
    }


    /**
     * On click outside autocomplete div or input close autocomplete
     * @param event click event
     */
    onBlur(event: any) {

        if (!event.relatedTarget || !/opt-\d*/.test(event.relatedTarget.id)) {
            this.openSelect = false;
        }

    }


    // REQURED METHODS FOR NGMODEL DESIGN PATTERN

    public validate(c: FormControl) {
        let errors = { ...c.errors }, hasError = false;

        if (this.label && !this.value) {
            hasError = true;
            errors['noSelectionError'] = { valid: false }
        }

        if (hasError || c.invalid) {
            return errors;
        } else {
            return null;
        }
    }

    /**
     * Reuired from ngModel design pattern to work
     * @param value initial value
     */
    writeValue(value: string): void {
        if (value && value !== this._value) {
            this.label = this.getOptionLabel(value);
            this.setValue(this.getOptionValue(value));
            this.cdr.markForCheck();
        }
    }

    onChange = (_: any) => { };
    onTouched = () => { };

    registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }
    registerOnTouched(fn: () => void): void { this.onTouched = fn; }

}