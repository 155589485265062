import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
// import { fab } from './brands/fa-brands';
import { fad } from './duotone/fa-duotone';
import { fal } from './light/fa-light';
import { far } from './regular/fa-regular';
import { fas } from './solid/fa-solid';


export function initializeIcons(library: FaIconLibrary) {

    // library.addIcons(...fab);
    library.addIcons(...fad);
    library.addIcons(...fal);
    library.addIcons(...far);
    library.addIcons(...fas);
}