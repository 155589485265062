import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { WorkInProgressComponent } from '@layout';
import { FeatureGuard, RoutingStrategy } from '@vedrai/vedrai-core';
import { AuthGuard } from '@core';
import { AccessDeniedComponent, MainLayoutComponent, NotFoundComponent } from '@layout';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { preload: true, menu: true, moduleTitle: 'menu.dashboard' }
      },
      {
        path: 'examine',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/examine/examine.module').then(m => m.ExamineModule),
        data: { preload: true, menu: true, moduleTitle: 'menu.examine', group:'examine' }
      },
      {
        path: 'ROI',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/ROI/ROI.module').then(m => m.ROIModule),
        canLoad: [FeatureGuard],
        data: { preload: true, menu: true, feature: 'ROI' }
      },
      {
        path: 'consumption-and-purchases',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/consumption-and-purchases/consumption-and-purchases.module').then(m => m.ConsumptionAndPurchasesModule),
        data: { preload: true, menu: true, moduleTitle: 'menu.consumption-and-purchases', group: 'consumption-and-purchases' }
      },
      {
        path: 'performance',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/performance/performance.module').then(m => m.PerformanceModule),
        canLoad: [FeatureGuard],
        data: { preload: true, menu: true, moduleTitle: 'menu.performance', feature: 'performance' }
      }
    ]
  },
  {
    path: 'access',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/access/access.module').then(m => m.AccessModule),
    data: { preload: true, access: true }
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
    data: { public: true }
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
    data: { public: true }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    enableTracing: false,
    preloadingStrategy: RoutingStrategy,
    relativeLinkResolution: 'legacy',
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'top',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule],
  providers: [RoutingStrategy]
})
export class AppRoutingModule { }
