import { Pipe, PipeTransform } from "@angular/core";
import moment from 'moment';

@Pipe({name: 'month'})
export class MonthPipe implements PipeTransform {
  transform(monthIndex: number, date: string): string {
 
    const startDate = moment(date);

    return startDate.add(monthIndex, 'M').format('MMMM YYYY');
  }
}