import { NgModule } from "@angular/core";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '@vedrai/vedrai-core';
import { UiModule, VedraiSkltnModule } from '@vedrai/vedrai-ui';
import { NgxEchartsModule } from "ngx-echarts";
import { ToastrModule } from "ngx-toastr";
import { initializeIcons } from "./modules/fontawesome";
import { SKLTN_VENDOR_CONFIG, VedraiKitVendorModule, VEDRAI_KIT_VENDOR_CONFIG } from './modules/vedrai-kit';
import { NGX_TRANSLATE_VENDOR_CONFIG } from './modules/ngx-translate';
import { NgxEchartsVendorModule, NGX_ECHARTS_VENDOR_CONFIG } from './modules/ngx-echarts';
import { NgBootstrapVendorModule } from "./modules/ng-bootstrap";
import { NGX_TOASTR_VENDOR_CONFIG } from "./modules/ngx-toastr";
import { Ng2StompjsVendorModule } from "./modules/ng2-stompjs";
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { NgxGoogleAnalyticsVendorModule, NGX_GOOGLE_ANALYTICS_CONFIG } from './modules/ngx-google-analytics';
import { DatadogVendorModule } from './modules/datadog';

const { 
    trackingCode = null, 
    initCommands = null, 
    uri = null,
    enableTracing = false
} = NGX_GOOGLE_ANALYTICS_CONFIG;

@NgModule({
    imports: [
        NgBootstrapVendorModule.forRoot(),
        Ng2StompjsVendorModule.forRoot(),
        NgxEchartsVendorModule.forRoot(),
        NgxGoogleAnalyticsVendorModule.forRoot(),
        VedraiKitVendorModule.forRoot(),
        DatadogVendorModule.forRoot(),

        NgxGoogleAnalyticsModule.forRoot(trackingCode, initCommands, uri, enableTracing),
        CoreModule.forRoot(VEDRAI_KIT_VENDOR_CONFIG),
        UiModule.forRoot(),
        VedraiSkltnModule.forRoot(SKLTN_VENDOR_CONFIG),
        TranslateModule.forRoot(NGX_TRANSLATE_VENDOR_CONFIG),
        NgxEchartsModule.forRoot(NGX_ECHARTS_VENDOR_CONFIG),
        ToastrModule.forRoot(NGX_TOASTR_VENDOR_CONFIG)
    ]
})
export class VendorsModule {
    constructor(library: FaIconLibrary) {
        initializeIcons(library);
    }
}