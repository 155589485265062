import { Component, Input } from '@angular/core';
import { WebsocketService } from '@vendors/modules/ng2-stompjs';
import { AuthService, UserService } from '@core';
import { BehaviorSubject } from 'rxjs';
import { LanguageService } from '@vendors/modules/ngx-translate/services/language.service';
import { ModalOptions, ModalsService } from '@vendors/modules/ng-bootstrap';
import { SwitchLanguageModalComponent } from '../switch-language-modal/switch-language-modal.component';


@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html'
})
export class UserMenuComponent {

  @Input() mobile: boolean = false;

  get currentUser$(): BehaviorSubject<any> {
    return this.userService.currentUser$;
  }

  get language$(): BehaviorSubject<string> {
    return this.languageService.language$;
  }

  constructor(
    private authService: AuthService,
    private websoketService: WebsocketService,
    private userService: UserService,
    private languageService: LanguageService,
    private modalsService: ModalsService
  ) { }

  logout() {
    this.websoketService.disconnect();
    this.authService.logout();
  }

  openSwitchLanguage() {
    this.modalsService.openModal(
      new ModalOptions({
        component: SwitchLanguageModalComponent,
        data: {
          title: 'languages.language',
          languages: this.languageService.languages,
          language: this.languageService.language,
          confirm: this.languageService.selectLanguage.bind(this.languageService)
        },
        size: 'sm'
      })
    );
  }
}
