<skltn-root>
  <div class="table-container">
    <table class="table" [class]="tableClass">
      <thead>
        <th *ngFor="let col of columns">
          <div skltn-bone class="header-column skltn-card-line mb-0"></div>
        </th>
        <th *ngIf="showActionsColumn"></th>
      </thead>
      <tbody>
        <tr *ngFor="let row of rows">
          <td *ngFor="let col of columns">
            <div skltn-bone class="skltn-card-title w-100 mb-0"></div>
          </td>
          <td *ngIf="showActionsColumn">
           <div class="flex-end-center m-0 p-0 ml--8">
            <div skltn-bone type="circle" class="skltn-card-avatar small w-4 m-0 mr-2"></div>
            <div skltn-bone type="circle" class="skltn-card-avatar small w-4 m-0"></div>
           </div>
          </td>
      </tbody>
    </table>
  </div>
</skltn-root>
