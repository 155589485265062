import {
    Commodity, ICategory, ICommodity,
    PeriodMetadata, PeriodsFactory, PeriodType, RecursivlyPeriodType
} from '@models';

export interface ICommodityDetail extends ICommodity {
    last_update: string;
    starred: boolean;
    acquired: boolean;
    category: ICategory;
    details: IDetail;
    order: number;
    listed: boolean;
    title: string;
    value: number;
    volume: number;
    available_granularities: RecursivlyPeriodType[];
    commodity_granularities: RecursivlyPeriodType[];
}

export class CommodityDetail extends Commodity {

    acquired: boolean;
    starred: boolean;
    last_update: string;
    category: ICategory;
    value: number;
    volume: number;
    order: number;
    listed: boolean;

    details: IDetail;
    detailsPrices: IDetailPrice[];

    defaultPeriod: PeriodType;
    available_granularities: RecursivlyPeriodType[];
    commodity_granularities: RecursivlyPeriodType[];
    periodGranularities: PeriodMetadata[];

    get categoryId() {
        return this.category?.id;
    }

    get minGranularityAvailable(): PeriodType {
        return PeriodsFactory.getPeriodType(this.commodity_granularities[0]);
    }

    constructor(commodity: ICommodityDetail) {
        super(commodity);

        this.acquired = commodity.acquired;
        this.starred = commodity.starred;
        this.last_update = commodity.last_update;
        this.value = commodity.value;
        this.volume = commodity.volume;
        this.order = commodity.order;
        this.listed = commodity.listed;
        this.category = commodity.category;

        this.available_granularities = commodity.available_granularities || [];
        this.commodity_granularities = commodity.commodity_granularities || [];
        this.periodGranularities = this.getPeriodsGranularities(this.available_granularities);
        this.defaultPeriod = this.getDefaultForecastPeriodType(this.periodGranularities);

        this.details = commodity.details;
        this.detailsPrices = this.getDetailsPrices(this.details);
    }

    private getPeriodsGranularities(available_granularities: RecursivlyPeriodType[]) {
        return PeriodsFactory.getPeriodsMetadata(available_granularities)?.reverse()
    }

    private getDefaultForecastPeriodType(periods: PeriodMetadata[]) {
        let defaultPeriod: PeriodType = 'week';
        const isAvailable = periods.find((period) => period.contains(defaultPeriod));

        if (!isAvailable) defaultPeriod = periods[0]?.periodType;

        return defaultPeriod;
    }

    private getDetailsPrices(details: IDetail) {
        return Object.values(details || {});
    }

}
export interface IDetail {
    closure_price: IDetailPrice;
    maximum_price: IDetailPrice;
    minimum_price: IDetailPrice;
    volume_price: IDetailPrice;
}

export interface IDetailPrice {
    display_name: string;
    description: string;
    unit_meas: string;
    value: number;
    trend: ITrend;
}

export interface ITrend {
    daily?: ITrendPeriod;
    weekly?: ITrendPeriod;
    monthly?: ITrendPeriod;
    quarterly?: ITrendPeriod;
    period?: ITrendPeriod;
}

export interface ITrendPeriod {
    value: number;
    percentage: number;
    start_date?: string;
    end_date?: string;
    trend: string;
}
