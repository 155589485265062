import config from '../../package.json';
const { name, version } = config;

export const appName = name;
export const environment = {
  production: true,
  name: 'dev',
  version,
  urlApiSSO: 'https://sso.dev.vedr.ai/api/sso',
  urlApiPrivate: 'https://adm-becky.dev.vedr.ai/api',
  urlApiPublic: 'https://adm-becky.dev.vedr.ai/api',
  urlApiNotifications: 'https://api.infer.dev.vedr.ai/notifications/v1',
  urlMarketplace: 'https://vedrai.com/soluzioni/becky/#title-5',
  broker: {
    debug: true,
    url: 'wss://notifications.vedrai.io:15674/ws',
    virtualHost: 'dev',
    topic: 'becky'
  },
  sentry: {
    dns: 'https://0f27958b28d146808c20e1c87d90f281@o477887.ingest.sentry.io/6116209',
    env: 'dev',
    tracingOrigins: ['localhost', 'https://yourserver.io/api'],
    tracesSampleRate: 0.1
  },
  datadog: {
    applicationId: '50707da8-4c8d-4643-8ea2-95493cf9fa1f',
    clientToken: 'pubbba522e4d2285423e3c376cb48eede6b',
    site: 'datadoghq.eu',
    service: 'vedrai-becky',
    env: 'development',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow' // 'allow' | 'mask' | 'mask-user-input'
  },
  googleAnalytics: {
    trackingCode: 'G-0XNKKXZGG6',
    initCommands: null,
    uri: null,
    enableTracing: true
  },
  languages: ['it', 'es', 'en']
};
