<!-- {{inLoading}} -->
<nav aria-label="breadcrumb" class="breadcrumb-container">
    <h2 class="text-white mb-0 mr-4">{{moduleTitle | translate}}</h2>

    <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
        <li class="breadcrumb-item">
            <a [routerLink]="'/dashboard'">
                <fa-icon [icon]="['fas', 'house']"></fa-icon>
            </a>
        </li>
        <ng-container *ngFor="let crumb of breadcrumbs; let i = index">
            <li *ngIf="!crumb.isHome" class="breadcrumb-item" [class.active]="i == breadcrumbs.length - 1">
                <a (click)="navigateTo(i)" class="cursor-pointer text-truncate">
                    {{crumb.path}} {{crumb.title | translate : crumb.titleData}}
                </a>
            </li>
        </ng-container>
    </ol>
</nav>