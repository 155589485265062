import vedraiTheme from '@configs/charts/themes/vedrai.theme.json';
import examineTheme from '@configs/charts/themes/examine.theme.json';

export const chartsThemes = [
    {
        name: 'vedrai',
        theme: vedraiTheme
    },
    {
        name: 'commodity',
        theme: examineTheme
    },
    {
        name: 'examine',
        theme: examineTheme
    }
]
