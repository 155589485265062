<li class="nav-item dropdown" ngbDropdown placement="bottom-right">

    <a class="nav-link dropdown-toggle" ngbDropdownToggle role="button">
        <fa-icon [icon]="['fas', 'bell']"></fa-icon>
        <div *ngIf="notificationsNumber$ | async as notificationsNumber" class="bell-counter">{{notificationsNumber}}
        </div>
    </a>
    <div class="dropdown-menu dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden" ngbDropdownMenu>
            <ng-container *ngIf="notificationsNumber$ | async as notificationsNumber; else noNotifications">
            <div class="px-3 py-3">
                <h6 class="text-sm text-muted m-0">
                    <span [innerHtml]="'notifications.number' | translate : 
                        (notificationsNumber | tagger : 'number' : 'b' : 'text-primary')">
                    </span>
                </h6>
            </div>
            <div class="list-group list-group-flush notifications-container">
                <a *ngFor="let notification of (notifications$ | async)" class="list-group-item list-group-item-action"
                    href="javascript:void()">
                    <div class="row align-items-center">
                        <vedrai-icon-alert [type]="notification.notify" size="sm"></vedrai-icon-alert>
                        <div class="col ml--2">
                            <div class="d-flex justify-content-between align-items-center">
                                <div>
                                    <h4 class="mb-0 text-sm">{{notification.title}}</h4>
                                </div>
                                <div class="text-right text-muted"><small>{{notification.created}}</small></div>
                            </div>
                            <p class="text-sm mb-0"> {{notification.description}} </p>
                        </div>
                    </div>
                </a>
            </div>
            <a class="dropdown-item text-center font-weight-bold py-3" (click)="clearNotifications()">{{'notifications.clean' | translate}}</a>
        </ng-container>


        <ng-template #noNotifications>
            <div class="px-3 py-3">
                <h6 class="text-sm text-muted m-0"> {{ 'notifications.not-available' | translate }} </h6>
            </div>
        </ng-template>

    </div>
</li>