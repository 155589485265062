import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-switch-language-modal',
    templateUrl: './switch-language-modal.component.html'
})
export class SwitchLanguageModalComponent {

    @Input() initialData: any;

    language: string;
    inLoading: boolean;

    get languages(): string[] {
        return this.initialData.languages;
    }

    get previousLanguage() {
        return this.initialData.language;
    }

    get confirm(): Function {
        return this.initialData.confirm;
    }

    constructor(private activeModalService: NgbActiveModal) { }

    close() {
        this.activeModalService.close();
    }

    async save() {
        this.inLoading = true;
        try {
            this.confirm(this.language);
            this.activeModalService.close();
        } catch (e) {
            console.error(`Unable to switch language: ${this.language}`)
        } finally {
            this.inLoading = false;
        }
    }
}
