import { assignColorBySentiment, getTime } from "@shared";

export type NotificationType = 'CTA' | 'P' | 'N';
export type Notifications = NNotification | PNotification;
// Call to action, progress, notification

export interface INotification {
    created: string;
    type: NotificationType;
    value: Notifications;
    consumed: boolean;
}

export class CTANotification {
    // TODO CTA notification
    id: string;
}

export class PNotification {
    id: string;
    entityType: string;
    progress: number;
    color: string;

    constructor(notice) {
        if (!notice) {
            this.progress = 0;
            return;
        } else {
            this.progress = notice.progress;
        }

        this.id = notice.id;
        this.entityType = notice.entityType;
        this.color = assignColorBySentiment(this.progress);
    }
}

export class NNotification {
    id: string;
    entityType: string;
    display_name: string;
    status: string;

    constructor(notice?: any) {
        if (!notice) {
            this.status = '404';
            return;
        }

        this.id = notice.id;
        this.display_name = notice.display_name;
        this.entityType = notice.entityType;
        this.status = notice.status + '';
    }
}

export const NOTIFICATION_MODELS = {
    CTA: CTANotification,
    N: NNotification,
    P: PNotification
}

export class Notification implements INotification {
    created: string;
    type: NotificationType;
    value: Notifications;
    title: string;
    description: string;
    notify: string;
    consumed: boolean = false;

    constructor(messageBody: string) {
        this.created = getTime();
        try {
            const message = JSON.parse(messageBody);

            if (message && message.type) {
                this.type = message.type;
                this.value = <Notifications>new NOTIFICATION_MODELS[this.type](message.value);
            }
        } catch (e) {
            this.type = 'N';
            this.value = new NOTIFICATION_MODELS[this.type]();
        }
    }

}