import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Table } from '@vedrai/vedrai-ui';
import { ForecastGroup } from '../../models/forecast/forecast-group.model';
import { Forecast } from '../../models/forecast/forecast.model';
import { TablesService } from '../../services/tables.service';

@Component({
    selector: 'app-forecast-detail-modal',
    templateUrl: './forecast-detail-modal.component.html',
    styleUrls: ['./forecast-detail-modal.component.scss']
})
export class ForecastDetailModalComponent {

    @Input() initialData: any;

    @ViewChild('valueTemplate', { static: true }) valueTemplate: TemplateRef<any>;
    @ViewChild('boundaryTemplate', { static: true }) boundaryTemplate: TemplateRef<any>;
    @ViewChild('trendTemplate', { static: true }) trendTemplate: TemplateRef<any>;
    @ViewChild('guessedTemplate', { static: true }) guessedTemplate: TemplateRef<any>;

    forecastsTable: Table;
    forecastsTableData: Forecast[];

    get forecastGroup(): ForecastGroup {
        return this.initialData?.forecastGroup;
    }

    get forecasts(): Forecast[] {
        return this.forecastGroup?.forecasts || [];
    }

    get targetPeriod() {
        return this.forecastGroup?.targetPeriod?.fullDate;
    }

    get targetPeriodType() {
        return this.forecastGroup?.periodType;
    }

    get groupValue() {
        return this.forecastGroup?.value;
    }

    get currency() {
        return this.initialData?.entity?.currency;
    }

    constructor(private activeModalService: NgbActiveModal, private tableService: TablesService) { }

    ngOnInit() {
        this.forecastsTable = new Table({
            configs: this.tableService.getTableConfigs('forecast-detail'),
            presentationConfigs: {
                targetPeriod: {
                    transform: (value) => value?.end.momentDate.format('D MMM yy')
                },
                value: {
                    templateContent: this.valueTemplate
                },
                boundary: {
                    templateContent: this.boundaryTemplate
                },
                forecast_trend: {
                    templateContent: this.trendTemplate
                },
                guessed: {
                    templateContent: this.guessedTemplate
                }
            }
        });

        this.forecastsTableData = [...this.forecasts];
    }

    close() {
        this.activeModalService.close();
    }

}