import { ActivatedRoute } from '@angular/router';
import { get } from '@vedrai/vedrai-core';

export class BreadCrumb {

    route: string[] = [];
    isHome: boolean;

    title: string;
    titleData: any;

    constructor(route: ActivatedRoute) {
        this.updateBreadCrumb(route);
    }

    getPath(route: ActivatedRoute) {
        const { snapshot: { url } } = route;
        return url.map((url) => url.path);
    }

    protected getTitleData(data: any, titleKey: string | Function) {
        if (!titleKey) return null;
        // if (!titleKey) return data;
        if (typeof titleKey == 'function') return titleKey(data);

        const path = titleKey.split('.');

        const titleData = get(data, path);
        if (!titleData) return null;
        return { [path[path.length - 1]]: titleData };
    }

    updateBreadCrumb(route: ActivatedRoute) {
        const { data }: any = route.snapshot;
        const { title, isHome, titleKey } = data;

        this.route = this.route.concat(this.getPath(route));
        this.titleData = this.getTitleData(data, titleKey) || this.titleData;

        //(!!titleKey && !this.titleData) ? 

        // : this.titleData;
        this.isHome = this.isHome || isHome === true;
        this.title = title || this.title;
    }
}