import { ChartTooltipModel } from './chart-tooltip-model.model';

export class ChartTooltip {

    getTooltip(models: ChartTooltipModel[]) {
        const [{ data }] = models;

        const headerHTML = this.getTooltipHeaderHTML(data);

        const seriesHTML = models.reduce((seriesHTML: string, model: ChartTooltipModel) =>
            seriesHTML + this.getTooltipSeriesHTML(model), '');

        return headerHTML + seriesHTML;
    }

    getTooltipHeaderHTML(date: string) {
        return /*html*/ `<div class="chart-tooltip-label">${date || '-'}</div>`;
    }

    getTooltipSeriesHTML({ name, marker, value, measureUnit }: ChartTooltipModel) {
        if (value == null) return '';

        const unitMeasureTemplate = measureUnit ? `<span class="chart-tooltip-unit-measure">${measureUnit}</span>` : '';

        return /*html*/ `<div class="chart-tooltip-series">
            <span class="chart-tooltip-name">${marker} ${name}</span>
            <div>
                <b>${value}</b>
                ${unitMeasureTemplate}
            </div>
        </div>`;
    }

    static getLineMarkerHTML(color: string) {
        return /*html*/ `<div class="chart-tooltip-line-marker" style="border-color:${color}"></div>`;
    }
}