
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { TaggerPipe } from './utilities/pipes/tagger.pipe';

@NgModule({
    declarations: [TaggerPipe],
    imports: [TranslateModule],
    exports: [TranslateModule, TaggerPipe]
})
export class NgxTranslateVendorModule {}