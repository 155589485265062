<div class="nav flex-start-center" ngbDropdown [placement]="'bottom-left'">

    <a class="btn flex-start-center btn-flat p-0" role="button" ngbDropdownToggle>
        <span *ngIf="mobile" class="avatar avatar-sm rounded-circle bg-gradient-primary mr-2">
            <fa-icon [icon]="['fas', 'user']"></fa-icon>
        </span>
        
        <fa-icon *ngIf="!mobile" [icon]="['fas', 'user']" class="ml-2 ml-1 mr-2 text-white"></fa-icon>
        
        <div class="media-body ml-2">
            <span class="mb-0 text-sm" [class.text-white]="!mobile">{{((currentUser$ | async)?.username) | titlecase}} </span>
        </div>
    </a>
    <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
        <a class="dropdown-item" (click)="logout()">
            <fa-icon [icon]="['fas', 'right-from-bracket']" class="mr-4"></fa-icon>
            <span>{{ 'operations.logout' | translate }}</span>
        </a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" (click)="openSwitchLanguage()">
            <fa-icon [icon]="['fas', 'globe']" class="mr-4"></fa-icon>
            <span>{{('languages.' + (language$ | async)) | translate}}</span>
        </a>
    </div>
</div>