import { PeriodType, PluralPeriodType, ShortPeriodType } from "../period-types.model";
import { Moment } from "moment";
import { IPeriod, Period } from "../period.model";
import { AddStaticsToInstance } from "../periods.factory";

@AddStaticsToInstance
export class Day extends Period implements IPeriod {

    public static periodType: PeriodType = 'day';
    public static shortPeriodType: ShortPeriodType = 'D';
    public static pluralPeriodType: PluralPeriodType = 'days';
    public static recursivlyPeriodType: PluralPeriodType = 'daily';
    public static occurenciesInYear: number = 365;
    
    public static icon: string[] = ['fas', 'calendar-day'];
    
    get fullDate() {
        return this.end.fullDate;
    }

    get shortDate() {
        return this.end.shortDate;
    }

    constructor(startDate?: string, endDate?: string) {
        super(startDate, endDate);

        this.fetchFullDate(this.getFullFormat);
        this.fetchShortDate(this.getShortFormat);
    }
  
    getFullFormat = (date: Moment) => date.format('D MMMM yyyy');
    getShortFormat = (date: Moment) => date.format('dddd DD');
}