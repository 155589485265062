<div class="modal-body modal-h-30 pb-0">
    <div *ngFor="let lang of languages; let i = index" class="custom-control custom-radio mb-3">
        <input class="custom-control-input" [id]="lang" type="radio"
            [(ngModel)]="language" [disabled]="previousLanguage == lang"
            [checked]="previousLanguage == lang" [value]="lang">
        <label class="custom-control-label" [for]="lang"> {{('languages.' + lang) | translate}}
        </label>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-link" (click)="close()"> {{ 'operations.cancel' | translate }} </button>
    <button class="btn btn-primary ml-auto" (click)="save()"> {{'operations.confirm' | translate }} </button>
</div>
<vedrai-local-loader [inLoading]="inLoading"></vedrai-local-loader>