import { Injectable } from '@angular/core';
import { deepClone, get } from '@vedrai/vedrai-core';

import tablesConfigs from '@configs/tables/tables.json';

export type TablesType = 'commodities' | 'forecast-detail' | 'materials' | 'supplies' | 'simulations' | 'simulation-detail';

@Injectable()
export class TablesService {

    getTableConfigs(type: TablesType) {
        return deepClone(get(tablesConfigs, type))
    }
}