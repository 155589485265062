import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuService } from '../../services/menu.service';
import { MenuType } from '../../models/menu/menu.model';
import { BreadcrumbsService } from '../../services/breadcrumbs.service';
import { BreadCrumb } from '../../models/bread-crumb/bread-crumb.model';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent {

    inLoading$: BehaviorSubject<boolean>;
    breadcrumbs$: BehaviorSubject<BreadCrumb[]>;
    moduleTitle$: BehaviorSubject<string>;

    constructor(private menuService: MenuService, private breadcrumbsService: BreadcrumbsService) {

        this.inLoading$ = this.breadcrumbsService.inLoading$;
        this.breadcrumbs$ = this.breadcrumbsService.breadcrumbs$;
        this.moduleTitle$ = this.breadcrumbsService.moduleTitle$;

    }

    toggleMenu(menuType: MenuType) {
        this.menuService.toggleMenu(menuType);
    }

}