import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ChartLegend } from '../../models/chart-legend/chart-legend.model';

@Component({
    selector: 'app-chart-legends',
    templateUrl: 'chart-legends.component.html'
})
export class ChartLegendsComponent {
    @Input() legends: ChartLegend[];
    @Output() toggleLegend: EventEmitter<ChartLegend>;

    constructor() {
        this.toggleLegend = new EventEmitter<ChartLegend>();
    }

    onToggleLegend(legend: ChartLegend) {
        legend.selected = !legend.selected;
        this.toggleLegend.emit(legend);
    }
}