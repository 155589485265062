import { Component, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'app-skltn-tabs',
    styleUrls: ['./skltn-tabs.component.scss'],
    templateUrl: 'skltn-tabs.component.html'
})
export class SkltnTabsComponent {

    @Input() headerTemplate: TemplateRef<any>;
}