import { Injectable } from '@angular/core';
import { Company, User } from '@core';
import { datadogRum } from '@datadog/browser-rum';

@Injectable()
export class DatadogService {

    trackUser(user: User, company: Company){

        datadogRum.setUser({
            id: `${user.id || 'not-available'}`,
            name: user.username,
            is_staff: user.is_staff ? 'yes' : 'no',
            company_id: company.id,
            company_name: company.name
        })
    }
}