import { Pipe, PipeTransform } from "@angular/core";
import { formatNumberWith2Decimals } from "@shared";

@Pipe({ name: 'round' })
export class RoundPipe implements PipeTransform {
  transform(value: any, showSymbol?: boolean): any {
    if (typeof value == 'number') {

      const parsedNumber = formatNumberWith2Decimals(value);
      const symbol = showSymbol && value > 0 ? '+' : '';
      return symbol + parsedNumber;
    };
    return value;
  }
}
