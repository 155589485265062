import { PeriodType, PluralPeriodType, ShortPeriodType } from "../period-types.model";
import { Moment } from "moment";
import { IPeriod, Period, PeriodDate } from "../period.model";
import { AddStaticsToInstance } from "../periods.factory";

@AddStaticsToInstance
export class Quarter extends Period implements IPeriod {

    public static periodType: PeriodType = 'quarter';
    public static shortPeriodType: ShortPeriodType = 'Q';
    public static pluralPeriodType: PluralPeriodType = 'quarters';
    public static recursivlyPeriodType: PluralPeriodType = 'quarterly';
    public static occurenciesInYear: number = 4;
    
    public static icon: string[] = ['fas', 'calendar-days'];

    get year() {
        return this.start.momentDate.format('yyyy')
    }

    get startDate() {
        return this.start.momentDate.format('DD MMMM');
    }
    
    set startDate(startDate: string) {
        this.start = new PeriodDate(startDate);
    }

    get endDate() {
        return this.end.momentDate.format('DD MMMM');
    }

    set endDate(endDate: string) {
        this.end = new PeriodDate(endDate);
    }

    constructor(startDate: string, endDate: string) {
        super(startDate, endDate);

        this.fetchFullDate(this.getFullFormat);
        this.fetchShortDate(this.getShortFormat);
    }
 
    getShortFormat = (date: Moment) => date.format('Q');
    getFullFormat = (date: Moment) => date.format('TQ');

}