import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment, appName } from './environments/environment';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { datadogRum } from '@datadog/browser-rum';

import { AppModule } from "./app/app.module";
import { DATADOG_CONFIG } from '@vendors/modules/datadog';

if (environment.sentry) {
  Sentry.init({
    dsn: environment.sentry.dns,
    environment: environment.sentry.env,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: environment.sentry.tracingOrigins,
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: environment.sentry.tracesSampleRate,
  });
}

if (environment.datadog) {
  datadogRum.init(DATADOG_CONFIG);

  datadogRum.startSessionReplayRecording();
}

if (environment.production) {
  enableProdMode();
} else {
  const { name, version } = environment;
  console.log(`${appName} env: ${name} version: ${version}`);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));