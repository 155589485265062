import { PeriodType, PluralPeriodType, ShortPeriodType } from "../period-types.model";
import { Moment } from "moment";
import { IPeriod, Period } from "../period.model";
import { AddStaticsToInstance } from "../periods.factory";

@AddStaticsToInstance
export class Year extends Period implements IPeriod {

    public static periodType: PeriodType = 'year';
    public static shortPeriodType: ShortPeriodType = 'Y';
    public static pluralPeriodType: PluralPeriodType = 'years';
    public static recursivlyPeriodType: PluralPeriodType = 'yearly';
    public static occurenciesInYear: number = 1;
  
    public static icon: string[] = ['fas', 'calendar-days'];

    get year(){
        return +this.start.fullDate;
    }

    get fullDate() {
        return this.end.fullDate;
    }
  
    constructor(startDate: string) {
        super(startDate, startDate);

        this.fetchFullDate(this.getFullFormat);
        this.fetchShortDate(this.getShortFormat);
    }

    getFullFormat = (date: Moment) => date.format('yyyy');
    getShortFormat = (date: Moment) => date.format('yy');

    getYearExtremesDays(){
        return {
            start: this.start.momentDate.startOf('year').format('yyyy-MM-DD'),
            end: this.end.momentDate.endOf('year').format('yyyy-MM-DD')
        }
    }
}