import { Injectable } from '@angular/core';
import { LanguageType } from '../models/types.model';
import { BaseLanguageService } from './base-language.service';


// TODO to kit
@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    get language$() {
        return this.langService.language$;
    }

    get language() {
        return this.langService.language;
    }

    set language(language: LanguageType) {
        this.langService.language = language;
    }

    get languages() {
        return this.langService.languages;
    }

    constructor(private langService: BaseLanguageService) { }

    selectLanguage(language: LanguageType) {
        if (!this.langService.isLanguageAvailable(language)) {
            console.error(language, ' Not supported');
            return;
        }
        this.langService.storeLanguage(language);
        window.location.reload(); // TODO remove this shit
    }

}