import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getStoredLanguage } from '../models/stored-language.model';
import { LanguageType } from '../models/types.model';
import { BaseLanguageService } from './base-language.service';


// TODO to kit
@Injectable({
    providedIn: 'root'
})
export class LanguageInitializerService {

    set language(language: LanguageType) {
        this.langService.language = language;
    }

    constructor(
        private langService: BaseLanguageService,
        private translate: TranslateService) { }

    async initLanguage() {
        let language = getStoredLanguage();

        if (!this.langService.isLanguageAvailable(language)) {
            const supportedLanguage = this.langService.getSupportedLanguage();
            console.error(`'${language}' Not supported, move to '${supportedLanguage}' language`);
            language = supportedLanguage;
        }

        this.language = language;
        this.translate.setDefaultLang(language);
        this.langService.setMomentLanguage(language);
        this.langService.localeInitializer(language);

        return this.langService.useLanguage(language);
    }
   
}