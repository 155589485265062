import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { addTranslationFolder } from '@vedrai/vedrai-core';

export const NGX_TRANSLATE_VENDOR_CONFIG = {
    loader: {
        provide: TranslateLoader,
        useFactory: addTranslationFolder,
        deps: [HttpClient],
    }
}