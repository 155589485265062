import { Injectable } from '@angular/core';

import { RequestService } from '@vedrai/vedrai-core';
import { map } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import {
    PeriodType, FullPeriodType, CommodityDetail, ICommodityDetail,
    CommodityDetailPrices, CommodityTimeSeries,
    CorrelatedCommodity, CorrelatedCommodityTimeSeries
} from '@models';
import { ITimeSeries } from '@vendors/modules/ngx-echarts';
import { IPagination, Pagination } from '@vendors/modules/vedrai-kit';
import { CommodityFilterType } from '@pages/examine/models/listed-type.model';



@Injectable({
    providedIn: 'root'
})
export class CommoditiesService {

    // TODO remove this map by changing be 
    fullPeriodTypes: { [K in PeriodType]: FullPeriodType } = {
        day: 'daily',
        week: 'weekly',
        month: 'monthly',
        quarter: 'quarterly',
        year: 'yearly'
    }

    constructor(private requestService: RequestService) { }

    getCommoditiesOptions(commodityFilterType?: CommodityFilterType) {
        return this.requestService.execRequest('get', 'commodities/entities', { params: { listed: commodityFilterType === 'all' ? null : commodityFilterType === 'listed' } });
    }

    getCommodities() {
        return this.requestService.execRequest('get', 'commodities?extra=commodity_granularities', {
            params: { limit: 4, offset: 0 }
        }).pipe(map((res: IPagination) => new Pagination<CommodityDetail>(res, CommodityDetail)));
    }

    getSwitchableCommodities() {
        return this.requestService.execRequest('get', 'commodities?extra=commodity_granularities', {
            params: { acquired: true }
        }).pipe(map((commodities: ICommodityDetail[]) => commodities.map((commodity) => new CommodityDetail(commodity))));
    }

    getCommodity(commodityId: number) {
        return this.requestService.execRequest('get', `commodities/${commodityId}?extra=available_granularities,commodity_granularities`)
            .pipe(map((commodity: ICommodityDetail) => new CommodityDetail(commodity)));
    }

    getCommoditiesDetails(listed: boolean = true) {
        return this.requestService.execRequest('get', `commodities/details`, {
            params: { extra: 'details.volume_price,category', listed }
        })
        .pipe(map((commoditiesDetails) => commoditiesDetails.map((commodity) => new CommodityDetailPrices(commodity))));
    }

    // TODO: create a form class for start - end - period
    getCommoditiesTimeSeries(commodities: CommodityDetail[]) {

        const acquiredCommodities = this.getAcquiredCommodities(commodities);
        const commoditiesIds = this.getCommoditiesIds(acquiredCommodities);

        return this.requestService.execRequest('get', `commodities/timeseries`, {
            params: { entity_id: commoditiesIds }
        }).pipe(map((timeSeries: ITimeSeries[]) => commodities.map((commodity: CommodityDetail) => {
                const ts = timeSeries.find((timeSeries: ITimeSeries) => timeSeries.entity?.id == commodity.id);
                return new CommodityTimeSeries(commodity, ts);
            })
        ));
    }

    getTimeSeriesForPeriod(period: PeriodType, currentCommodity: CommodityDetail, commodityToCompare: CorrelatedCommodity, startDate: string, endDate: string) {
        return forkJoin({
            timeseries: this.getCommodityTimeSeries(currentCommodity, startDate, endDate, period),
            correlatedTimeseries: commodityToCompare ? this.getCorrelatedCommodityTimeSeries(commodityToCompare, startDate, endDate, period) : of(null)
        });
    }

    getCommodityTimeSeries(commodity: CommodityDetail, startDate: string, endDate: string, period: PeriodType) {
        return this.requestService.execRequest('get', `commodities/${commodity.id}/timeseries`, {
            params: { start_date: startDate, end_date: endDate, granularity: this.fullPeriodTypes[period] }
        }).pipe(map((timeseries: ITimeSeries) => new CommodityTimeSeries(commodity, timeseries)));
    }

    getCommodityTimeSeriesByDefault(commodity: CommodityDetail) {
        return this.requestService.execRequest('get', `commodities/${commodity.id}/timeseries`)
        .pipe(map((timeseries: ITimeSeries) => new CommodityTimeSeries(commodity, timeseries)));
    }

    getCorrelatedCommodityTimeSeries(commodity: CorrelatedCommodity, startDate: string, endDate: string, period: PeriodType) {
        return this.requestService.execRequest('get', `commodities/${commodity.id}/timeseries`, {
            params: { start_date: startDate, end_date: endDate, granularity: this.fullPeriodTypes[period] }
        }).pipe(map((timeseries: ITimeSeries) => new CorrelatedCommodityTimeSeries(commodity, timeseries)));
    }

    updateFavorite(commodityId: number, starred: boolean) {
        return this.requestService.execRequest('post', `commodities/${commodityId}/user_preferences`, {
            starred
        });
    }

    switchCommodityOrder(commodityId: number, order: number) {
        return this.requestService.execRequest('post', `commodities/${commodityId}/user_preferences`, { order });
    }

    getCommodityDetail(commodityId: number, endDate: string, period: PeriodType) {
        return this.requestService.execRequest('get', `commodities/${commodityId}/details`, {
            params: { end_date: endDate, time_interval: period, extra: 'category,details.maximum_price,details.minimum_price,details.volume_price,available_granularities,commodity_granularities' }
        })
        .pipe(map((commodityDetails) => new CommodityDetail(commodityDetails)));
    }

    getCorrelatedCommodities(commodityId: number, startDate: string, endDate: string) {
        return this.requestService.execRequest('get', `commodities/${commodityId}/correlations?extra=commodity.category,commodity.acquired`, {
            params: { start_date: startDate, end_date: endDate }
        })
        .pipe(map((commodities) => commodities.map((commodity) => new CorrelatedCommodity(commodity))));
    }

    private getAcquiredCommodities(commodities: CommodityDetail[]) {
        return commodities.filter((commodity: CommodityDetail) => commodity.acquired)
    }

    private getCommoditiesIds(commodities: CommodityDetail[]) {
        return commodities.map((commodity) => commodity.id);
    }

    private getCommodityById(commodities: CommodityDetail[], id: number) {
        return commodities.find((commodity: CommodityDetail) => commodity.id == id);
    }

}
