import { IParsedTimeSeries, ITimeSeries, TimeSeries } from '@vendors/modules/ngx-echarts';
import { SeriesType, CompareSeriesType } from '../echarts-types.model';
import { CorrelatedCommodity } from '../../entities/commodity/correlated-commodity.model';
import { CommoditySeries } from '../series/commodity-series.model';

export class CorrelatedCommodityTimeSeries extends TimeSeries<CorrelatedCommodity> {

    series: CommoditySeries[];

    constructor(commodity: CorrelatedCommodity, timeSeries: ITimeSeries) {
        super(commodity, timeSeries);
    }

    initSeries(parsedTimeSeries: IParsedTimeSeries) {
        const { paramsKeys, timeSeries } = parsedTimeSeries;
        return paramsKeys.map((param: SeriesType) => new CommoditySeries({
            id: <CompareSeriesType>`compare-${param}`,
            seriesType: param,
            label: this.entity.display_name,
            values: timeSeries[param]
        }));
    }
}