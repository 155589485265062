export interface IChartLegend {
    name: string;
    color: string;
    id: string;
    selected?: boolean;
}

export class ChartLegend {
    id: string;
    name: string;
    color: string;
    selected: boolean = true;

    constructor(legend: IChartLegend) {
        this.id = legend.id;
        this.name = legend.name;
        this.color = legend.color;

        if (legend.selected != undefined) this.selected = legend.selected;
    }
}