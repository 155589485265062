import { BreadCrumbGroup } from './../models/bread-crumb/bread-crumb-group.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BreadCrumb } from '../models/bread-crumb/bread-crumb.model';

@Injectable()
export class BreadcrumbsService {

    inLoading$: BehaviorSubject<boolean>;
    breadcrumbs$: BehaviorSubject<BreadCrumb[]>;
    moduleTitle$: BehaviorSubject<string>;

    constructor() {
        this.inLoading$ = new BehaviorSubject(false);
        this.breadcrumbs$ = new BehaviorSubject([]);
        this.moduleTitle$ = new BehaviorSubject(null);
    }

    get breadcrumbs() {
        return this.breadcrumbs$.getValue();
    }

    get moduleTitle() {
        return this.moduleTitle$.getValue();
    }

    inLoading(value: boolean) {
        this.inLoading$.next(value);
    }

    addBreadcrumbGroup(breadCrumbGroup: BreadCrumbGroup) {
        this.moduleTitle$.next(breadCrumbGroup.title);
        this.breadcrumbs$.next(breadCrumbGroup.getBreadCrumbs());
    }
}