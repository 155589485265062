import { environment } from '@env/environment';
import { SkltnConfig } from '@vedrai/vedrai-ui';
import featureConfigs from '@configs/features/features.json';
import { IFeatureConfigs } from '@vedrai/vedrai-core';


export const SKLTN_VENDOR_CONFIG: SkltnConfig = {
    flareWidth: '150px',
    bgFill: '#efefef',
};

export const VEDRAI_KIT_VENDOR_CONFIG = {
    environment,
    featureConfigs: <IFeatureConfigs>featureConfigs
};