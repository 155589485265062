import { IconName, IconPrefix, SizeProp } from '@fortawesome/fontawesome-svg-core';

export interface IMenuVoice {
    divider?: boolean;
    label?: string;
    route?: string[];
    icon?: [IconPrefix, IconName];
    iconSize?: SizeProp;
    iconSwapOpacity?: boolean;
    class?: string;
    featureFlag?: string;
}

export class MenuVoice {
    divider: boolean;
    label: string;
    route: string[];
    icon: [IconPrefix, IconName];
    iconSize: SizeProp;
    iconSwapOpacity: boolean;
    class: string;
    featureFlag: string;

    constructor(menuVoice: IMenuVoice) {
        this.divider = menuVoice.divider || false;
        this.label = menuVoice.label || 'TBD';
        this.route = menuVoice.route || ['/'];
        this.icon = menuVoice.icon || ['fab', 'question'];
        this.iconSize = menuVoice.iconSize || 'sm';
        this.iconSwapOpacity = menuVoice.iconSwapOpacity || false;
        this.class = menuVoice.class || '';
        this.featureFlag = menuVoice.featureFlag;
    }
}
