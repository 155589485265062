import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SkltnTableComponent } from "./skltn-table/skltn-table.component";
import { SkltnTabsComponent } from './skltn-tabs/skltn-tabs.component';
import { SkltnPaginatorComponent } from './skltn-paginator/skltn-paginator.component';
import { VedraiSkltnModule } from "@vedrai/vedrai-ui";

@NgModule({
    imports: [
        CommonModule,
        VedraiSkltnModule
    ],
    declarations: [
        SkltnTabsComponent,
        SkltnTableComponent,
        SkltnPaginatorComponent
    ],
    exports: [
        SkltnTabsComponent,
        SkltnTableComponent,
        SkltnPaginatorComponent
    ]
})
export class SkltnsVendorModule { }