import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { User, Company } from '@core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Injectable()
export class NgxGoogleAnalyticsService {

    constructor(private $gaService: GoogleAnalyticsService) { }

    trackUser(user: User, company: Company) {
        if (!environment.googleAnalytics.trackingCode) return;

        this.$gaService.gtag('set', 'user_properties', {
            user_id: user.id,
            user_name: user.username,
            is_staff: user.is_staff,
            company_id: company.id,
            company_name: company.name
        });
    }
}