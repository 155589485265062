import { Injectable } from '@angular/core';
import { Configs, IConfigs } from '../../models/configs.model';
import { BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ConfigsService {

  $configs: BehaviorSubject<Configs>

  get configs() {
    return this.$configs.getValue();
  }

  set configs(configs: Configs) {
    this.$configs.next(configs);
  }

  constructor() {
    this.$configs = new BehaviorSubject(new Configs());
  }

  async initConfigs() {
    let configs = new Configs();

    try {
      configs = await this.getConfigurations();
    } catch (error) {
      console.error('Configuration not available: ', error, ' Default in use');
    }

    this.configs = configs;
  }

  getConfigurations() {
    return of({}) // this.requestService.execRequest('get', 'configurations')
      .pipe(map((configs: IConfigs) => new Configs(configs))).toPromise();
  }
}
