import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CheckboxDirective } from './checkbox-value/checkbox-value.directive';
import { HorizontalScrollDirective } from './horizontal-scroll/horizontal-scroll.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        CheckboxDirective,
        HorizontalScrollDirective
    ],
    exports: [
        CheckboxDirective,
        HorizontalScrollDirective
    ]
})
export class DirectivesModule { }