<header class="header bg-gradient-header">
    <nav class="navbar navbar-top navbar-expand-md navbar-dark" id="navbar-main">
        <div class="container-fluid px-4">

            <app-bread-crumb [breadcrumbs]="breadcrumbs$ | async" [moduleTitle]="moduleTitle$ | async"
                [inLoading]="inLoading$ | async" class="breadcrumb-navbar">
            </app-bread-crumb>


            <ul class="navbar-nav align-items-center ml-md-auto flex-row">
                <app-notifications-menu></app-notifications-menu>
                <app-apps-menu></app-apps-menu>
                <app-user-menu class="d-none d-md-block ml-1"></app-user-menu>
            </ul>
        </div>
    </nav>
</header>