import { PeriodType, PluralPeriodType, ShortPeriodType } from "../period-types.model";
import { Moment } from "moment";
import { IPeriod, Period } from "../period.model";
import { AddStaticsToInstance } from "../periods.factory";


@AddStaticsToInstance
export class Week extends Period implements IPeriod {

    public static periodType: PeriodType = 'week';
    public static shortPeriodType: ShortPeriodType = 'W';
    public static pluralPeriodType: PluralPeriodType = 'weeks';
    public static recursivlyPeriodType: PluralPeriodType = 'weekly';
    public static occurenciesInYear: number = 54;

    public static icon: string[] = ['fas', 'calendar-week'];

    get fullDate() {
        return this.end.fullDate;
    }

    constructor(startDate: string, endDate: string) {
        super(startDate, endDate);

        this.fetchFullDate(this.getFullFormat);
        this.fetchShortDate(this.getShortFormat);
    }

    getFullFormat = (date: Moment) => date.format('D MMMM yy');
    getShortFormat = (date: Moment) => date.format('D MMMM yy');

}