import { ForecastTrendType } from "./forecast-types.model";
import { ForecastGroup, IForecastGroup } from "./forecast-group.model";
import { PeriodType } from '@models';
import { ForecastBoundary, IForecastBoundary } from "./forecast-boundary.model";

export interface IForecastData<T> {
    entity: T;
    results: IForecastGroup[];
    boundary: IForecastBoundary;
}

export class ForecastData<T> {

    entity: T;
    boundary: ForecastBoundary;
    trends: ForecastTrendType[] = ['ascending', 'stable', 'descending'];
    periodType: PeriodType;

    forecastGroupReference: ForecastGroup;
    forecastsGroups: ForecastGroup[];

    trendsReferenceGroup: ForecastGroup[];
    trendsGroups: ForecastGroup[][];

    get upperBound(): number {
        return this.boundary.upper_bound;
    }

    get lowerBound(): number {
        return this.boundary.lower_bound;
    }

    constructor(forecastData: IForecastData<T>, periodType: PeriodType) {

        this.entity = forecastData.entity;
        this.boundary = new ForecastBoundary(forecastData.boundary);
        this.periodType = periodType;

        this.forecastGroupReference = this.getForecastGroupReference(forecastData.results);
        this.forecastsGroups = this.getForecastGroups(forecastData.results);

        this.trendsReferenceGroup = this.getTrendsReferenceGroup(this.forecastGroupReference);
        this.trendsGroups = this.getTrendsGroups(this.forecastsGroups);
    }

    private getTrendsReferenceGroup(forecastGroupReference: ForecastGroup) {
        return [null, forecastGroupReference, null];
    }

    private getTrendsGroups(forecastsGroups: ForecastGroup[]) {
        return this.trends.map((trend: ForecastTrendType) =>
            forecastsGroups.map((forecastGroup: ForecastGroup) => this.filterForecast(forecastGroup, trend))
        );
    }

    private getForecastGroupReference(forecastGroups: IForecastGroup[]) {
        const reference = forecastGroups[0];
        return new ForecastGroup(reference, this.periodType);
    }

    private getForecastGroups(forecastGroups: IForecastGroup[]) {
        const groups = forecastGroups.slice(1);
        return groups.map(forecastGroup => new ForecastGroup(forecastGroup, this.periodType));
    }

    private filterForecast(forecastGroup: ForecastGroup, trend: ForecastTrendType) {
        if(forecastGroup.isStable && trend === 'stable') {
            return forecastGroup;
        }

        return forecastGroup.lastForecast?.trend == trend ? forecastGroup : null;
    }

}