import { IMenuGroup, MenuGroup } from './menu-group.model';
import { IMenuVoice, MenuVoice } from './menu-voice.model';

export interface IMenuSection {
    label?: string;
    separator?: boolean;
    groups?: IMenuGroup[];
    voices?: IMenuVoice[];
}


export class MenuSection{
    label: string;
    separator: boolean;
    groups: MenuGroup[];
    voices: MenuVoice[];

    constructor(menuSection: IMenuSection){
        this.label = menuSection.label;
        this.separator = menuSection.separator;
        this.groups = (menuSection.groups || []).map((group: IMenuGroup) => new MenuGroup(group));
        this.voices = (menuSection.voices || []).map((voice: IMenuVoice) => new MenuVoice(voice));
    }
}