
import { TranslateService } from "@ngx-translate/core";
import { AppInjector } from "@vedrai/vedrai-core";
import { ISeries, Series } from '@vendors/modules/ngx-echarts';
import { CompareSeriesType, SeriesGroupsType, SeriesType } from '../echarts-types.model';


export interface ICommoditySeries extends ISeries {
    id: SeriesType | CompareSeriesType;
    seriesType: string;
    selected?: boolean;
    group?: SeriesGroupsType;
    label?: string;
    values: any;
}

export class CommoditySeries extends Series  {

    id: SeriesType | CompareSeriesType;
    seriesType: string;
    selected: boolean;
    group: SeriesGroupsType;
    label: string;
    values: any;

    #translate: TranslateService;

    constructor(series: ICommoditySeries) {
        super(series)
        this.#translate = AppInjector.get(TranslateService);

        this.id = series.id;
        this.seriesType = series.seriesType;
        this.selected = series.selected || false;
        this.group = this.getSeriesGroup(this.seriesType);
        this.label = series.label || <string>this.#translate.instant(`examine.params.${this.seriesType}`);

        this.values = series.values;
    }

    getSeriesGroup(seriesType: string): SeriesGroupsType {
        return seriesType == 'volume' ? 'volume' : 'candles';
    }
}
