export interface ICommodity {
    id: number;
    name: string;
    display_name: string;
    description?: string;
    currency?: string;
    unit_meas?: string;
    unit_meas_short?: string;
}

export class Commodity {
    id: number;
    name: string;
    display_name: string;
    description: string;
    currency: string;
    unit_meas_full: string;
    unit_meas_short: string;
    listed?: boolean;

    constructor(commodity: ICommodity) {
        this.id = commodity.id;
        this.name = commodity.name;
        this.display_name = commodity.display_name;
        this.description = commodity.description;
        this.currency = commodity.currency;
        this.unit_meas_full = commodity.unit_meas;
        this.unit_meas_short = commodity.unit_meas_short;
    }

    get unit_meas() {
        return this.unit_meas_short;
    }
}
