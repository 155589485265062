import { Injectable } from '@angular/core';
import { deepClone, get } from '@vedrai/vedrai-core';

import { chartsThemes } from '../models/charts/charts-themes.model';
import commodityCharts from '@configs/charts/charts/commodities.json';
import ROICharts from '@configs/charts/charts/ROI.json';
import performance from '@configs/charts/charts/performance.json';
import simulation from '@configs/charts/charts/simulation.json';
import consumptionAndPurchases from '@configs/charts/charts/consumption-and-purchases.json';

export type ChartsType = 'commodity' | 'ROI' | 'performance' | 'consumptionAndPurchases' | 'simulation';

@Injectable()
export class ChartsService {

    get commodity(): any {
        return commodityCharts;
    }

    get ROI(): any {
        return ROICharts;
    }

    get performance(): any {
        return performance;
    }
    
    get consumptionAndPurchases(): any {
        return consumptionAndPurchases;
    }

    get simulation(): any {
        return simulation;
    }
    
    getChart(type: ChartsType, path: string, ChartClass?) {
        const chartConfig = deepClone(get(this[type], path));
        return ChartClass ? new ChartClass(chartConfig) : chartConfig;
    }

    getThemeColors(themeName: string) {
        return deepClone(chartsThemes.find((theme) => theme.name == themeName)?.theme?.theme.color || []);
    }

}