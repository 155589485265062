import { formatNumber } from '@angular/common';
import { getStoredLanguage } from '@vendors/modules/ngx-translate';

export function roundNumber(number: number) {
    if (number > 10000 || number < -10000) {
        return Math.round(number);
    }
    return number;
}

export function roundDecimal(num: number, decimalsLength: number = 2): number {
    if (decimalsAreToLow(num)) {
        return Math.trunc(num);
    }

    if (decimalsAreToHigh(num)) {
        return Math.ceil(num);
    }

    return +(num.toFixed(decimalsLength))
}

function decimalsAreToLow(num: number): boolean {
    const truncatedNum = Math.trunc(num);
    const onlyTwoDecimalsNumber = Math.floor(num * 100) / 100;
    return truncatedNum === onlyTwoDecimalsNumber;
}

function decimalsAreToHigh(num: number): boolean {
    return num % 1 > 0.99;
}

export function formatNumberWith2Decimals(number: number): string {
    return formatNumber(roundDecimal(number), getStoredLanguage(), '1.2-2');
}

export function roundDecimalsWithTresholds(num: number, firstTreshold: number = 100, secondTreshold: number = 1000): number {
    if(num < firstTreshold) return roundDecimal(num);
    if(num < secondTreshold) return roundDecimal(num, 1);
    return Math.floor(num);
}