import { StaticClassProvider, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export class DynamicLocaleId extends String {
    constructor(protected service: TranslateService) {
        super();
    }

    toString() {
        return this.service.currentLang;
    }
}

export const LocaleProvider: StaticClassProvider = {
    provide: LOCALE_ID,
    useClass: DynamicLocaleId,
    deps: [TranslateService]
};