import { Injectable } from "@angular/core";
import {
    InjectableRxStompConfig, RxStompService,
    rxStompServiceFactory, StompState
} from "@stomp/ng2-stompjs";
import { Message } from '@stomp/stompjs';
import { Subscription } from "rxjs";
import { mergeMap, takeWhile, tap } from "rxjs/operators";
import { AuthNotificationsService } from './auth-notifications.service';
import { NotificationsService } from './notifications.service';

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {

    rxStompService: RxStompService;
    connection: Subscription;
    attemptLimit: number = 20;

    get authService() {
        return this.authNotificationsService;
    }

    get configs(): InjectableRxStompConfig {
        return this.authService.configs;
    }

    get isConnectionAvailable$() {
        return this.authService.isConnectionAvailable$;
    }

    get isConnectionAvailable() {
        return this.authService.isConnectionAvailable;
    }

    get topic() {
        return this.authService.topic;
    }

    get queueId() {
        return this.authService.queueId;
    }

    constructor(private authNotificationsService: AuthNotificationsService,
        private notificationsService: NotificationsService) {


        this.isConnectionAvailable$.pipe(
            takeWhile((isAvailable) => isAvailable),
            tap(() => {
                this.rxStompService = rxStompServiceFactory(this.configs);
                this.rxStompService.activate();
                this.connectToQueue();
            }),
            mergeMap(() => this.trySocketConnection())
        ).subscribe(state => {
            if (this.isFailedToConnect(state)) {
                this.attemptLimit--;
            }
            if (!this.attemptLimit) {
                this.rxStompService.deactivate();
            }
        });
    }

    private connectToQueue() {
        this.connection = this.rxStompService.watch(`/queue/${this.queueId}`)
            .subscribe((message: Message) => this.notificationsService.manageNotifications(message.body));
    }

    private trySocketConnection() {
        return this.rxStompService.connectionState$
            .pipe(takeWhile(() => this.canRetry()))
    }

    private canRetry() {
        return !!this.attemptLimit;
    }

    private isFailedToConnect(state) {
        return StompState[state] == 'CLOSED'
    }

    disconnect() {
        if (this.isConnectionAvailable && this.connection) {
            this.rxStompService.deactivate();
            this.connection.unsubscribe();
        }
    }
}