import { HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilsService } from '@vendors/modules/vedrai-kit';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';

import configs from '@configs/errors/errors.json';
import { matchRule } from '@vedrai/vedrai-core';
import { AuthService } from '../authorizations/auth.service';
import { StatusType } from '../../models/core-types.model';
import { ModalsService } from '@vendors/modules/ng-bootstrap';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    get userIsLoggedIn() {
        return this.authService.isLoggedIn;
    }

    getNotificationsConfigs(status: number) {
        return configs.notifications[status] || {};
    }

    getAPIConfigs(url: string, status: number) {
        const { urlsApi, api } = configs;

        const currentUrlApi = urlsApi.find((urlApi) => matchRule(url, urlApi.url))

        return (currentUrlApi ? currentUrlApi.api[status] : api[status]) || {};
    }

    constructor(private authService: AuthService, private utils: UtilsService, private modalsService: ModalsService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(catchError((error: HttpErrorResponse) => {
                const url = request.url;
                const status = error.status;

                if (this.isUserUnauthorized(url, status)) {
                    return this.forceLogout(url);
                }

                if (this.isNotificationExcluded(url)) {
                    return throwError(this.getErrorMessage(error));
                }

                if (this.isNotificationError(url)) {
                    this.showNotificationError(status);
                } else {
                    this.showError(url, status);
                }

                return throwError(this.getErrorMessage(error));
            }));
    }

    private showNotificationError(status: number) {
        const { skipNotification, type = 'error' } = this.getNotificationsConfigs(status);

        if (skipNotification) return;

        this.showToast(type, `notifications-errors.${status}`);
    }

    private forceLogout(url: string): Observable<never> {
        if (this.isLogoutRequest(url)) {
            this.authService.applyLogout();
            return throwError(`Force redirect`);
        }
        this.modalsService.dismissAllModals();
        this.authService.logout();
        this.showToast('warning', `errors.force-logout`);
        return throwError(`Force logout`);
    }

    private showError(url: string, status: number) {
        const { skipNotification, type = 'error', label } = this.getAPIConfigs(url, status);

        if (skipNotification) return;

        this.showToast(type, label || `errors.${status}`);
    }

    private showToast(type: StatusType, description: string) {
        this.utils.openToast(type, { description });
    }

    private isNotificationError(url: string) {
        return url.includes(environment.urlApiNotifications);
    }

    private isNotificationExcluded(url: string) {
        const excludedUrls = configs['exclude-notifications-from-url'];
        const excludedUrl = excludedUrls.find((excludedUrl) => url.includes(excludedUrl))
        return !!excludedUrl;
    }

    private isUserUnauthorized(url: string, status: number) {
        const { forceLogout } = this.getAPIConfigs(url, status);
        return forceLogout && this.userIsLoggedIn;
    }

    private getErrorMessage(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = `Error: ${error.error.message}`;
        } else {
            errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
        }
        return errorMessage;
    }

    private isLogoutRequest(url: string) {
        return url.includes('/logout');
    }

}

