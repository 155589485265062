import { PeriodType, RecursivlyPeriodType } from "../../dates/period-types.model";
import { PeriodsFactory } from "../../dates/periods.factory";
import { Category, ICategory } from "../category/category.model";

export interface ICorrelatedCommodity {
    commodity: {
        id: number;
        name: string;
        display_name: string;
        acquired: boolean;
        listed: boolean;
        available_granularities: RecursivlyPeriodType[];
        commodity_granularities: RecursivlyPeriodType[];
        category: ICategory;
    };
    correlation: number;
}

export class CorrelatedCommodity {
    id: number;
    name: string;
    display_name: string;
    correlation: number;
    listed: boolean;
    available_granularities: RecursivlyPeriodType[] = [];
    commodity_granularities: RecursivlyPeriodType[] = [];
    category: Category;

    score: number
    positive: boolean;
    acquired: boolean;
    selected: boolean = false;

    get minGranularityAvailable(): PeriodType {
        return PeriodsFactory.getPeriodType(this.commodity_granularities[0]);
    }

    constructor(commodity: ICorrelatedCommodity) {
        this.id = commodity.commodity.id;
        this.name = commodity.commodity.name;
        this.display_name = commodity.commodity.display_name;
        this.correlation = commodity.correlation;
        this.acquired = commodity.commodity.acquired;
        this.listed = commodity.commodity.listed;
        this.category = new Category(commodity.commodity.category);
        this.available_granularities = commodity.commodity.available_granularities;
        this.commodity_granularities = commodity.commodity.commodity_granularities;

        this.score = Math.abs(this.correlation) * 100;
        this.positive = this.correlation > 0;
    }

    isGranularityAvailable(granularity: PeriodType): boolean {
        return this.commodity_granularities.includes( PeriodsFactory.getRecursivlyPeriodType(granularity));
    }
}