import { ModuleWithProviders, NgModule } from "@angular/core";
import { NgxEchartsModule } from "ngx-echarts";
import { ChartsService } from "./services/charts.service";

@NgModule({
    imports: [
        NgxEchartsModule
    ],
    exports: [NgxEchartsModule]
})
export class NgxEchartsVendorModule {
    static forRoot(): ModuleWithProviders<NgxEchartsVendorModule> {
        return {
            ngModule: NgxEchartsVendorModule,
            providers: [ChartsService]
        }
    }
}