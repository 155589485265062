import { IconPrefix, IconName, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { IMenuVoice, MenuVoice } from './menu-voice.model';

export interface IMenuGroup {
    label?: string;
    voices?: IMenuVoice[];
    class?: string;
    icon?: [IconPrefix, IconName];
    iconSize?: SizeProp;
    isCollapsed?: boolean;
}

export class MenuGroup {
    label: string;
    voices: MenuVoice[];
    class: string;
    icon: [IconPrefix, IconName];
    iconSize: SizeProp;
    isCollapsed: boolean;

    constructor(menuGroup: IMenuGroup) {
        this.label = menuGroup.label || 'TBD';
        this.class = menuGroup.class || '';
        this.icon = menuGroup.icon || ['fab', 'question'];
        this.iconSize = menuGroup.iconSize || 'sm';
        this.isCollapsed = menuGroup.isCollapsed || true;
        this.voices = (menuGroup.voices || []).map((voice: IMenuVoice) => new MenuVoice(voice));
    }
}