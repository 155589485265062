import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        while (route.firstChild) {
            route = route.firstChild;
        }

        return this.canAccessToPages(route.data);
    }

    private canAccessToPages(data: any) {

        // Public pages check
        const { public: isPublic, access: isAccess } = data;

        if (isAccess) {
            if (this.authService.isLoggedIn) {
                this.router.navigate(['dashboard']);
                return false;
            } else {
                return true;
            }
        }

        if (isPublic) return true;

        return this.authService.isLoggedIn;

    }

}