import { IModalStyleClass } from './modal-style-class.model';
import { ModalSizeType } from './modals-type.model';

export interface IModalOptionsConfigs {
    size?: ModalSizeType;
    classes?: IModalStyleClass;
    data?: any;
}

export class ModalOptionsConfigs {
    size: ModalSizeType;
    classes: IModalStyleClass;
    data: any;

    constructor(modalOptions: IModalOptionsConfigs) {
        this.size = modalOptions.size || 'md';
        this.classes = modalOptions.classes || {};
        this.data = modalOptions.data || {};
    }
}