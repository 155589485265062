import { NgModule } from '@angular/core';
import { MonthPipe } from './month/month.pipe';
import { QuarterPipe } from './quarter/quarter.pipe';
import { RoundPipe } from './round/round.pipe';

@NgModule({
    declarations: [
        QuarterPipe,
        MonthPipe,
        RoundPipe
    ],
    exports: [
        QuarterPipe,
        MonthPipe,
        RoundPipe
    ]
})
export class PipesModule { }
