import { LOCATION_INITIALIZED } from '@angular/common';
import { Injector } from '@angular/core';
import { AuthService } from '@core';
import { LanguageInitializerService } from '@vendors/modules/ngx-translate/services/language-initializer.service';


// TODO to kit
export function appInitializer(languageService: LanguageInitializerService, authService: AuthService, injector: Injector) {
    return () => initializeApp(languageService, authService, injector);
}

async function initializeApp(languageService: LanguageInitializerService, authService: AuthService, injector: Injector) {
    await initTranslations(languageService, injector);
    await authService.initAuthentication();
}

async function initTranslations(languageService: LanguageInitializerService, injector: Injector) {
    try {
        await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
        await languageService.initLanguage();
        console.log(`Successfully initialized '${languageService.language}' language.`);
    } catch (error) {
        console.error(`Problem with language initialization.`, error);
    }
}