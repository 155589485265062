import { PeriodType, PluralPeriodType, ShortPeriodType } from "../period-types.model";
import { Moment } from "moment";
import { IPeriod, Period } from "../period.model";
import { AddStaticsToInstance } from "../periods.factory";

@AddStaticsToInstance
export class Month extends Period implements IPeriod {

    public static periodType: PeriodType = 'month';
    public static shortPeriodType: ShortPeriodType = 'M';
    public static pluralPeriodType: PluralPeriodType = 'months';
    public static recursivlyPeriodType: PluralPeriodType = 'monthly';
    public static occurenciesInYear: number = 12;
    
    public static icon: string[] = ['fas', 'calendar-days'];

    get fullDate() {
        return this.end.fullDate;
    }
    
    constructor(startDate: string, endDate: string) {
        super(startDate, endDate);

        this.fetchFullDate(this.getFullFormat);
        this.fetchShortDate(this.getShortFormat);
    }

    getFullFormat = (date: Moment) => date.format('MMMM yyyy');
    getShortFormat = (date: Moment) => date.format('MM');
}