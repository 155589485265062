import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-image-placeholder',
    templateUrl: './image-placeholder.component.html'
})
export class ImagePlaceholderComponent {

    @Input() imageTitle: string;
    @Input() subtitle: string;
    @Input() description: string;
    @Input() icon: string[];
    @Input() image: string;
    @Input() imageSize: 'lg' | 'md' | 'sm' = 'lg';

    constructor() { }
}