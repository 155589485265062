import { TimeSeries, ITimeSeries, IParsedTimeSeries } from '@vendors/modules/ngx-echarts';
import { SeriesType } from '../echarts-types.model';
import { CommodityDetail } from '../../entities/commodity/commodity-detail.model';
import { CommoditySeries } from '../series/commodity-series.model';


export class CommodityTimeSeries extends TimeSeries<CommodityDetail> {

    series: CommoditySeries[];

    constructor(commodity: CommodityDetail, timeSeries: ITimeSeries) {
        super(commodity, timeSeries);
        this.entity.available_granularities = timeSeries?.entity?.available_granularities;
    }

    initSeries(parsedTimeSeries: IParsedTimeSeries) {
        const { paramsKeys, timeSeries } = parsedTimeSeries;
        return paramsKeys.map((param: SeriesType) => new CommoditySeries({
            id: param,
            seriesType: param,
            values: timeSeries[param]
        }));
    }
}