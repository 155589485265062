
export function assignColorBySentiment(sentiment) {

    if (sentiment >= 25 && sentiment < 50) {
        return 'orange';
    }

    if (sentiment >= 50 && sentiment < 75) {
        return 'yellow';
    }

    if (sentiment < 25) {
        return 'red';
    }

    if (sentiment >= 75) {
        return 'green';
    }
}


export function assignGradientColorsBySentiment(sentiment) {

    if (sentiment >= 25 && sentiment < 50) {
        return ['#ff7538', '#ffc838'];
    }

    if (sentiment >= 50 && sentiment < 75) {
        return ['#ffd600', '#fdc560'];
    }

    if (sentiment < 25) {
        return ['#E34234', '#e38b34'];
    }

    if (sentiment >= 75) {
        return ['#50C878', '#50c8aa'];
    }
}