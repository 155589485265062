

import { NgModule } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";


@NgModule({
    imports: [FontAwesomeModule],
    exports: [FontAwesomeModule]
})
export class FontAwesomeVendorModule {}