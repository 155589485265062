import { ActivatedRoute } from '@angular/router';
export class DataRoute {

    private privateKeys: string[] = ['title', 'moduleTitle', 'titleKey', 'menu', 'preload', 'group', 'isHome'];

    data: any = {};

    constructor(privateKeys?: string[]) {

        if (!privateKeys?.length) return;

        this.privateKeys = this.privateKeys.concat(privateKeys);
    }

    updateData(route: ActivatedRoute) {
        let nextData: any = this.removePrivateKeys({...route.snapshot.data});
        for (let key in nextData) {
            if (this.data[key] !== undefined)
                console.warn(`Routing -> duplicate data key: ${key}`);
        }
        this.data = { ...this.data, ...nextData };
    }

    private removePrivateKeys(data: any) {
        this.privateKeys.forEach((key) => {
            delete data[key];
        });
        return data;
    }

}