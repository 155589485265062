import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ShopService {

    storeUrl: string = 'https://vedrai.com/soluzioni/becky/#title-5';

    goToShop() {
        window.open(this.storeUrl, '_blank');
    }

}