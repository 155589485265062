export interface ICategory {
    id: number;
    name: string;
    display_name: string;
}

export class Category {
    id: number;
    name: string;
    display_name: string;
    
    constructor(category: ICategory) {
        Object.assign(this, category);
    }
}