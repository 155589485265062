import { ModuleWithProviders, NgModule } from "@angular/core";
import { AuthNotificationsService } from "./services/auth-notifications.service";
import { RxStompService } from "@stomp/ng2-stompjs";

@NgModule({
   providers: [
    AuthNotificationsService,
    RxStompService
   ]
})
export class Ng2StompjsVendorModule {
   static forRoot(): ModuleWithProviders<Ng2StompjsVendorModule> {
      return {
          ngModule: Ng2StompjsVendorModule,
          providers: [
            AuthNotificationsService,
            RxStompService
          ]
      }
  }
}