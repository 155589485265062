<div class="modal-body modal-h-80 px-0 pb-0">
    <div data-key="forecast-group-header" class="mb-3 px-3">
        <span *ngIf="targetPeriodType == 'week'"><b>{{targetPeriod}}</b> {{('forecast-detail.detail-title.' +
            targetPeriodType) | translate}}</span>
        <span *ngIf="targetPeriodType != 'week'">{{('forecast-detail.detail-title.' + targetPeriodType) | translate}}
            <b>{{targetPeriod}}</b></span>
        <h2 class="mb-0 mt-1">
            <span>{{groupValue | round}} {{currency}}</span>
        </h2>
    </div>
    <vedrai-table class="forecasts-fixed-header-table" [configs]="forecastsTable.configs" [data]="forecastsTableData"></vedrai-table>
</div>
<div class="modal-footer flex-end-center">
    <button type="button" class="btn btn-primary" (click)="close()">Ok</button>
</div>

<ng-template #valueTemplate let-value="value">
    {{value | round}} {{currency}}
</ng-template>

<ng-template #boundaryTemplate let-row>
    {{row.lowerInterval | round}} - {{row.upperInterval | round}} {{currency}}
</ng-template>

<ng-template #trendTemplate let-trend="value">
    <fa-icon [icon]="['fas', (
        trend == 'ascending' ? 'arrow-trend-up' :
        (trend == 'descending' ? 'arrow-trend-down' : 'equals')
    )]" class="mr-1"></fa-icon>
    {{('forecast.trends.' + trend) | translate}}
</ng-template>

<ng-template #guessedTemplate let-guessed="value">

    <div class="flex-start-center">
        <span class="badge badge-dot">
            <i [ngClass]="{
                'bg-success': guessed === 'guessed' || guessed === 'almost_guessed_right',
                'bg-danger': guessed === 'not_guessed',
                'bg-orange':  guessed === 'almost_guessed_wrong' 
            }"></i>
        </span>
        {{('forecast.confidence.' + guessed) | translate}}
    </div>
</ng-template>