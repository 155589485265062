import { PeriodClassType, PeriodType, PeriodTypes, PluralPeriodType, RecursivlyPeriodType, ShortPeriodType } from "./period-types.model";

export class PeriodMetadata {
    icon: string[];
    periodType: PeriodType;
    shortPeriodType: ShortPeriodType;
    pluralPeriodType: PluralPeriodType;
    recursivlyPeriodType: RecursivlyPeriodType;

    constructor(periodClass: PeriodClassType) {
        this.icon = periodClass.icon;
        this.periodType = periodClass.periodType;
        this.shortPeriodType = periodClass.shortPeriodType;
        this.pluralPeriodType = periodClass.pluralPeriodType;
        this.recursivlyPeriodType = periodClass.recursivlyPeriodType;
    }

    contains(periodType: PeriodTypes){
        return this.periodType === periodType ||
               this.shortPeriodType === periodType ||
               this.pluralPeriodType === periodType || 
               this.recursivlyPeriodType === periodType;
    }
}