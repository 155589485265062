import {
    faCalendarDay,
    faCalendarDays,
    faCalendarWeek,
    faAward,
    faMedal,
    faTrophy,
    faTrophyStar,
    faHouse,
    faBell,
    faUser,
    faRightFromBracket,
    faStar,
    faMagnifyingGlass,
    faCartShopping,
    faCircleInfo,
    faEllipsisVertical,
    faRightLeft,
    faEquals,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faArrowDown,
    faTimes,
    faInfo,
    faThumbTack,
    faChartLine,
    faLock,
    faSitemap,
    faChartUser,
    faWarehouseFull,
    faTimer,
    faChevronDown,
    faChevronUp,
    IconDefinition,
    faDash,
    faArrowTrendUp,
    faArrowTrendDown,
    faGlobe,
    faPlus,
    faPencil,
    faTrashCan,
    faXmarkLarge,
    faDownload,
    faCopy
} from '@fortawesome/pro-solid-svg-icons';

export const fas: IconDefinition[] = [
    faCalendarDay,
    faCalendarDays,
    faCalendarWeek,
    faAward,
    faMedal,
    faTrophy,
    faTrophyStar,
    faHouse,
    faBell,
    faUser,
    faRightFromBracket,
    faStar,
    faMagnifyingGlass,
    faCartShopping,
    faCircleInfo,
    faEllipsisVertical,
    faRightLeft,
    faEquals,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faArrowDown,
    faTimes,
    faInfo,
    faThumbTack,
    faChartLine,
    faLock,
    faSitemap,
    faChartUser,
    faWarehouseFull,
    faTimer,
    faChevronDown,
    faChevronUp,
    faDash,
    faArrowTrendUp,
    faArrowTrendDown,
    faGlobe,
    faPlus,
    faTrashCan,
    faPencil,
    faXmarkLarge,
    faDownload,
    faCopy
];