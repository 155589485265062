import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@vedrai/vedrai-ui';
import { NgxTranslateVendorModule } from '@vendors/modules/ngx-translate';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        UiModule,

        NgxTranslateVendorModule
    ],
})
export class SharedModule { }
