import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'optionsListStyle'
})
export class OptionsListStylePipe implements PipeTransform {

    transform(option: any, styleFn: Function) {
       return typeof styleFn === 'function' && option ? styleFn(option) : '';
    }

}