export interface IProduct {
    display_name: string;
    code: string;
    description?: any;
    url?: any;
    logo: string;
    active: boolean;
}

export class Product {
    display_name: string;
    code: string;
    description?: any;
    url?: any;
    logo: string;
    active: boolean;
    isCurrentProduct: boolean; 

    constructor(product: IProduct) {
        this.display_name = product.display_name;
        this.code = product.code;
        this.description = product.description;
        this.url = product.url;
        this.logo = product.logo;
        this.active = product.active;
        this.isCurrentProduct = false;
    }
}
