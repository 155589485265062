<div *ngIf="reference" class="mb-3 pt-2 text-center mx-3">
    <span [innerHTML]="'forecast.' + referencePeriod?.periodType + '.title' | translate : 
            { date: referencePeriod?.fullDate }"></span>
</div>
<div class="forecasts-cointainer">
    <div class="forecasts-table" *ngIf="!inLoading">
        <div class="trends-column">
            <div *ngFor="let trend of trends" class="trend-title">
                {{('forecast.trends.' + trend + '-price') | translate}}
            </div>
            <div class="treshold-label-top text-muted">{{upperBound}}</div>
            <div class="treshold-label-bottom text-muted">{{lowerBound}}</div>
            <div class="trend-arrow"></div>
        </div>
        <div class="forecasts-table-body" appHorizontalScroll [enable]="fullScroll">
            <ng-container *ngIf="reference">
                <ng-container *ngTemplateOutlet="referenceColumn"></ng-container>
            </ng-container>
            <ng-container *ngTemplateOutlet="forecastColumn"></ng-container>
        </div>
    
        <div class="treshold-line-top"></div>
        <div class="treshold-line-bottom"></div>

        <div class="reference-arrow"></div>
    </div>
    <vedrai-local-loader [inLoading]="inLoading"></vedrai-local-loader>
</div>

<ng-template #referenceColumn>
    <div class="reference-column">
        <div class="forecasts-row">
            <div class="forecast-header">
                <ng-container *ngTemplateOutlet="headerCell; context: { period: referencePeriod, isReference: true }">
                </ng-container>
            </div>
        </div>
        <div *ngFor="let trend of trends; let i = index" class="forecasts-row">
            <ng-container *ngTemplateOutlet="trendsReferenceGroup[i] ? referenceForecastGroupCell : forecastCellPlaceholder; 
                context: { forecastGroup: trendsReferenceGroup[i], forecast: trendsReferenceGroup[i]?.lastForecast }">
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #forecastColumn>
    <div class="future-forecasts" appHorizontalScroll [enable]="!fullScroll">
        <div class="forecasts-row">
            <div *ngFor="let forecastGroup of forecastGroups" class="forecast-header">
                <ng-container *ngTemplateOutlet="headerCell; context: { period: forecastGroup.targetPeriod }">
                </ng-container>
            </div>
        </div>
        <div *ngFor="let trendsGroup of trendsGroups; let i = index" class="forecasts-row">
            <ng-container *ngFor="let forecastGroup of trendsGroup">
                <ng-container *ngIf="!!forecastGroup">
                    <ng-container *ngTemplateOutlet="
                    (forecastGroup?.isForFuture ? futureForecastGroupCell : pastForecastGroupCell); 
                    context: { forecastGroup, forecast: forecastGroup?.lastForecast }">
                    </ng-container>
                </ng-container>
                <div *ngIf="!forecastGroup" class="forecast-cell placeholder"></div>
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #headerCell let-period="period" let-isReference="isReference">
    <span class="d-block font-weight-600">
        {{('forecast.' + period?.periodType + '.' + (isReference? 'reference' : 'forecast') + '-title') | translate }}
    </span>
    <span class="text-sm text-muted d-block mt-1">
        {{period?.fullDate | titlecase}}
    </span>
</ng-template>

<ng-template #referenceForecastGroupCell let-forecastGroup="forecastGroup" let-forecast="forecast">
    <div class="forecast-cell visited" [class.cursor-pointer]="!hideHistory" [class]="forecast?.trend"
        (click)="onOpenForecastsDetail(forecastGroup)">
        <span class="forecast-confidence">
            {{forecastGroup.roundedValue }}<span class="text-md ml-1">{{entity?.currency}}</span>
        </span>
        <div *ngIf="!hideHistory" class="flex-between-center w-100">
            <div class="flex-start-center flex-row-reverse">
                <ng-container *ngFor="let forecast of forecastGroup?.forecasts | slice : 0 : 5">
                    <span class="badge badge-dot badge-dot-sm pt-1">
                        <i [ngClass]="{
                            'bg-success': forecast.guessed === 'guessed' ||  forecast.guessed === 'almost_guessed_right',
                            'bg-danger': forecast.guessed === 'not_guessed',
                            'bg-orange': forecast.guessed === 'almost_guessed_wrong' 
                        }" class="mr-2">

                            <fa-icon class="text-white" size="sm" [icon]="['fas', (
                                forecast.forecast_trend == 'ascending' ? 'arrow-trend-up' : (
                                    forecast.forecast_trend == 'descending' ? 'arrow-trend-down' : 'equals'
                                )
                            )]"></fa-icon>
                        </i>
                    </span>
                </ng-container>
            </div>
            <fa-icon class="pb-2" size="sm" [icon]="['fas', 'magnifying-glass']"></fa-icon>
        </div>
    </div>
</ng-template>

<ng-template #pastForecastGroupCell let-forecastGroup="forecastGroup" let-forecast="forecast">
    <div class="forecast-cell visited" [class]="forecastGroup.trend">
        <span class="forecast-confidence">
            {{forecastGroup.roundedValue }}<span class="text-md ml-1">{{entity?.currency}}</span>
        </span>
        <div>
            <span class="badge badge-pill badge-sm cursor-default"
                [ngClass]="{
                    'badge-expected': forecast.guessed === 'guessed' ||  forecast.guessed === 'almost_guessed_right',
                    'badge-not-expected': forecast.guessed === 'not_guessed',
                    'badge-almost-expected': forecast.guessed === 'almost_guessed_wrong' 
                }" [openDelay]="300" placement="top" container="body"
                [ngbTooltip]="'forecast.confidence-tooltip.' + forecast.guessed | translate : { confidence:  forecast.confidence } ">
                {{('forecast.confidence.' + forecast.guessed) | translate}}
            </span>
        </div>
    </div>
</ng-template>

<ng-template #futureForecastGroupCell let-forecastGroup="forecastGroup" let-forecast="forecast">
    <div class="forecast-cell" [class]="forecast.trend" [class.selected]="enableDrillDown"
        (click)="onPeriodDrillDown(forecastGroup)">
        <span class="forecast-confidence">
            <ng-container *ngIf="!hideConfindences">{{forecast.confidence}}<span class="text-md ml-1">%</span></ng-container>
            <span class="text-xl ml-1" *ngIf="hideConfindences">-</span>
        </span>
        <div class="flex-between-center w-100 mt-1">
            <span class="badge badge-pill badge-sm badge-forecast">
                {{('forecast.selected.' + forecastGroup.selected) | translate}}
            </span>
            <fa-icon *ngIf="enableDrillDown" class="text-white" size="sm"
                [icon]="['fas', 'magnifying-glass']">
            </fa-icon>
        </div>
    </div>
</ng-template>

<ng-template #forecastCellPlaceholder>
    <div class="forecast-cell placeholder"></div>
</ng-template>