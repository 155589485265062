import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PeriodInstanceType, PeriodMetadata, PeriodType } from '@models';
import { ForecastGroup } from '../../models/forecast/forecast-group.model';
import { ForecastData } from '../../models/forecast/forecast-data.model';
import { ForecastTrendType } from '../../models/forecast/forecast-types.model';
import { ModalOptions, ModalsService } from '@vendors/modules/ng-bootstrap';
import { ForecastDetailModalComponent } from '../forecast-detail-modal/forecast-detail-modal.component';

@Component({
    selector: 'app-forecast',
    templateUrl: './forecast.component.html',
    styleUrls: ['./forecast.component.scss']
})
export class ForecastComponent {

    @Input() hideHistory: boolean;
    @Input() forecastData: ForecastData<any>;
    @Input() enableDrillDown: boolean;
    @Input() inLoading: boolean;
    
    //TODO: remove this input when not listed confidences will be fixed
    @Input() hideConfindences: boolean = false;

    @Output() periodDrillDown: EventEmitter<ForecastGroup>;

    get entity() {
        return this.forecastData?.entity;
    }

    get upperBound(): string {
        return `${this.forecastData?.upperBound} ${this.entity?.currency}`;
    }

    get lowerBound(): string {
        return `${this.forecastData?.lowerBound} ${this.entity?.currency}`;
    }

    get trends(): ForecastTrendType[] {
        return this.forecastData?.trends;
    }

    get reference(): ForecastGroup {
        return this.forecastData?.forecastGroupReference;
    }

    get referencePeriod(): PeriodInstanceType {
        return this.reference?.targetPeriod;
    }

    get trendsReferenceGroup(): ForecastGroup[] {
        return this.forecastData?.trendsReferenceGroup;
    }

    get forecastGroups(): ForecastGroup[] {
        return this.forecastData?.forecastsGroups;
    }

    get trendsGroups(): ForecastGroup[][] {
        return this.forecastData?.trendsGroups;
    }

    get fullScroll() {
        return this.detectMobile();
    }

    private detectMobile() {
        return (window.innerWidth <= 800);
    }

    constructor(private modalsService: ModalsService) {
        this.periodDrillDown = new EventEmitter();
    }

    onOpenForecastsDetail(forecastGroup: ForecastGroup) {
        if (this.hideHistory) return;
        this.modalsService.openModal(new ModalOptions({
            component: ForecastDetailModalComponent,
            data: {
                title: 'forecast-detail.modal-detail-title',
                forecastGroup,
                entity: this.entity
            },
            size: 'xl'
        }));
    }

    onPeriodDrillDown(forecastGroup: ForecastGroup) {
        if (!this.enableDrillDown) return;

        this.periodDrillDown.emit(forecastGroup);
    }
}