import { IModalOptionsConfigs, ModalOptionsConfigs } from './modal-options-configs.model';

export interface IModalOptions<T> extends IModalOptionsConfigs {
    component: T;
    isEmbedded?: boolean;
}

export class ModalOptions<T> extends ModalOptionsConfigs {

    component: T;
    isEmbedded: boolean;

    constructor(modalOptions: IModalOptions<T>) {
        super(modalOptions);
        this.component = modalOptions.component;
        this.isEmbedded = modalOptions.isEmbedded ?? true;
    }

}
