import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const isApiSSOUrl = request.url.startsWith(environment.urlApiSSO + '/login');
        const headers = {};

        if (isApiSSOUrl) {
            headers['X-AUTHENTICATION-COOKIE'] = 'true';
        }

        request = request.clone({
            withCredentials: true,
            setHeaders: headers
        });
        return next.handle(request);
    }
}