
import { CommodityPriceType } from '../entities-types.model';
import { CommodityDetail, ITrendPeriod, ICommodityDetail } from './commodity-detail.model';


export class CommodityDetailPrices extends CommodityDetail {

    priceType: CommodityPriceType = 'closure_price';
    value: number;

    day_variation: ITrendPeriod;
    week_variation: ITrendPeriod;
    month_variation: ITrendPeriod;
    period_variation: ITrendPeriod;

    // quarter_variation: ITrendPeriod;

    constructor(commodity: ICommodityDetail) {
        super(commodity);
        this.setPriceType(this.priceType);
    }

    setPriceType(priceType: CommodityPriceType) {
        this.priceType = priceType;
        this.fetchDetailPrice(priceType);
    }

    getDetailPrice(priceType: CommodityPriceType) {
        return this.details[priceType];
    }

    private fetchDetailPrice(priceType: CommodityPriceType) {
        const { trend: { daily, weekly, monthly, period }, value } = this.getDetailPrice(priceType)
        this.day_variation = daily;
        this.week_variation = weekly;
        this.month_variation = monthly;
        this.period_variation = period;
        this.value = value;
    }
}