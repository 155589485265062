import { Component } from '@angular/core';
import { Product, UserService } from '@core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@env/environment';

@Component({
  selector: 'app-apps-menu',
  templateUrl: './apps-menu.component.html'
})
export class AppsMenuComponent {

  products$: BehaviorSubject<Product[]>;
  currentProduct: Product;

  get currentAppCode() {
    return this.currentProduct?.code || environment.name;
  }

  constructor(private userService: UserService) {
    this.products$ = this.userService.products$;
    this.currentProduct = this.userService.currentProduct;
  }


  navigateTo(virtualAgentCode: string) {
    if (virtualAgentCode == this.currentAppCode) return;
    const [url] = window.location.href.split('#');
    const nextVAUrl = url.replace(this.currentAppCode, virtualAgentCode);
    window.location.replace(nextVAUrl);
  }

}
