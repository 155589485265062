export interface ICompany {
    id: number;
    name: string;
    code: string;
    start_date: string;
    company_mail?: any;
    ba_mail: string;
    pm_mail: string;
}

export class Company {
    id: number;
    name: string;
    code: string;
    start_date: string;
    company_mail?: any;
    ba_mail: string;
    pm_mail: string;

    constructor(company: ICompany) {
        this.id = company.id;
        this.name = company.name;
        this.code = company.code;
        this.start_date = company.start_date;
        this.company_mail = company.company_mail;
        this.ba_mail = company.ba_mail;
        this.pm_mail = company.pm_mail;
    }
}