import { ModuleWithProviders, NgModule } from "@angular/core";
import { DatadogService } from './services/datadog.service';

@NgModule()
export class DatadogVendorModule {
    static forRoot(): ModuleWithProviders<DatadogVendorModule> {
        return {
            ngModule: DatadogVendorModule,
            providers: [
                DatadogService
            ]
        }
    }
}