import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { appName } from '@env/environment';
import { RequestService } from '@vedrai/vedrai-core';
import { Company } from '../../models/company.model';
import { Product } from '../../models/product.model';
import { IUser, User } from '../../models/user.model';
import { DatadogService } from '@vendors/modules/datadog';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    company$: BehaviorSubject<Company>;
    products$: BehaviorSubject<Product[]>;
    currentProduct$: BehaviorSubject<Product>;
    currentUser$: BehaviorSubject<User>;

    constructor(private requestService: RequestService, private datadogService: DatadogService) {
        this.company$ = new BehaviorSubject<Company>(null);
        this.products$ = new BehaviorSubject<Product[]>([]);
        this.currentUser$ = new BehaviorSubject<User>(null);
        this.currentProduct$ = new BehaviorSubject<Product>(null)
    }

    get company(): Company {
        return this.company$.getValue();
    }

    set company(company: Company) {
        this.company$.next(company);
    }

    get products(): Product[] {
        return this.products$.getValue();
    }

    set products(products: Product[]) {
        this.products$.next(products);
    }

    get currentProduct(): Product {
        return this.currentProduct$.getValue();
    }

    set currentProduct(agent: Product) {
        this.currentProduct$.next(agent);
    }

    get currentUser(): User {
        return this.currentUser$.getValue();
    }

    set currentUser(user: User) {
        this.currentUser$.next(user);
    }

    get userId() {
        return this.currentUser.id;
    }

    get isProductActive() {
        return this.currentProduct?.active;
    }

    consumeUser(user: IUser) {
        try {
            const { modules, company } = user;

            this.currentUser = new User(user);
            this.company = new Company(company);
            this.products = modules.map(product => new Product(product));
            this.currentProduct = this.products.find(product => product.code == appName);
            
            this.datadogService.trackUser(this.currentUser, this.company);

            if(this.currentProduct){
                this.currentProduct.isCurrentProduct = true;
            }
        } catch (e) {
            console.error(`User error ${e}`);
        }
    }

    getUser(): Observable<IUser> {
        return this.requestService.execSSORequest('get', 'user');
    }

}