import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { CoreModule, FeatureFlagDirective } from "@vedrai/vedrai-core";
import { UiModule, VedraiSkltnModule } from '@vedrai/vedrai-ui';
import { FontAwesomeVendorModule } from '../fontawesome';
import { NgBootstrapVendorModule } from '../ng-bootstrap';
import { NgxTranslateVendorModule } from '../ngx-translate';
import { TablesService } from './services/tables.service';
import { UrlParamsService } from './services/url-params.service';
import { UtilsService } from './services/utils.service';
import { DirectivesModule } from './utilities/directives/directives.module';
import { PipesModule } from './utilities/pipes/pipes.module';
import { OptionsListLabelPipe } from './utilities/pipes/_options-list/_options-list-label.pipe';
import { OptionsListStylePipe } from './utilities/pipes/_options-list/_options-list-style.pipe';
import { ChartLegendsComponent } from './widgets/chart-legends/chart-legends.component';
import { ForecastDetailModalComponent } from './widgets/forecast-detail-modal/forecast-detail-modal.component';
import { ForecastComponent } from './widgets/forecast/forecast.component';
import { ImagePlaceholderComponent } from './widgets/image-placeholder/image-placeholder.component';
import { SelectComponent } from './widgets/select/select.component';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgBootstrapVendorModule,
        NgxTranslateVendorModule,
        FontAwesomeVendorModule,

        CoreModule,
        UiModule,
        VedraiSkltnModule,

        DirectivesModule,
        PipesModule,
        FeatureFlagDirective
    ],
    declarations: [
        OptionsListLabelPipe,
        OptionsListStylePipe,

        ImagePlaceholderComponent,
        ForecastComponent,
        ForecastDetailModalComponent,
        SelectComponent,
        ChartLegendsComponent
    ],
    exports: [
        CoreModule,
        UiModule,
        VedraiSkltnModule,

        DirectivesModule,
        PipesModule,
        ImagePlaceholderComponent,
        ForecastComponent,
        ForecastDetailModalComponent,
        SelectComponent,
        FeatureFlagDirective,
        ChartLegendsComponent
    ]
})
export class VedraiKitVendorModule {
    static forRoot(): ModuleWithProviders<VedraiKitVendorModule> {
        return {
            ngModule: VedraiKitVendorModule,
            providers: [
                TablesService,
                UtilsService,

                UrlParamsService
            ]
        }
    }
}