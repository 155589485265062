import moment from "moment";

const dateFormat = 'yyyy-MM-DD';


/**
 * @returns today
 */
export function today() {
    return moment().format(dateFormat);
}

/**
 * return time in Hour and minutes
 * @param date 
 * @returns HH:mm
 */
export function getTime(date?: string) {
    const parsedDate = date ? moment(date) : moment();
    return parsedDate.format('HH:mm')
}

/**
 * Subtract business days by ingoring sunday and saturday
 * @param businessDaysNumber 
 * @param date reference date to subtract days
 * @returns day in the past yyyy-MM-DD
 */
export function subtractBusinessDays(businessDaysNumber: number, date?: string) {
    const Sunday = 0;
    const Saturday = 6;

    const newDate = date ? moment(date) : moment();

    while (businessDaysNumber > 0) {
        newDate.subtract(1, 'd');
        if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
            businessDaysNumber--;
        }
    }

    return newDate.format(dateFormat);
}

/**
 * Moment add utility
 * @param number number to add
 * @param periodUnit unit of period 'd', 'w', 'years', etc..  based on moment unitOfTime
 * @param date reference date to add days
 * @returns add formatted in yyyy-MM-DD
 */
 export function add(number: number, periodUnit: moment.unitOfTime.DurationConstructor, date: string) {
    return moment(date).add(number, periodUnit).format(dateFormat);
}

/**
 * Moment subtract utility
 * @param number number to subtract
 * @param periodUnit unit of period 'd', 'w', 'years', etc..  based on moment unitOfTime
 * @param date reference date to subtract days
 * @returns subtraction formatted in yyyy-MM-DD
 */
export function subtract(number: number, periodUnit: moment.unitOfTime.DurationConstructor, date: string) {
    return moment(date).subtract(number, periodUnit).format(dateFormat);
}

export function endOf(periodUnit: moment.unitOfTime.DurationConstructor, date: string){
    return moment(date).endOf(periodUnit).format(dateFormat);
}

export function startOf(periodUnit: moment.unitOfTime.DurationConstructor, date: string){
    return moment(date).startOf(periodUnit).format(dateFormat);
}